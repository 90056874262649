import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "./subedi.png";

const Navbar = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showlist, setShowlist] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);

  const handleNavList = () => {
    setShowlist(!showlist);
  };

  const handleDropdown = (event) => {
    event.stopPropagation();
    setShowDropdown((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const isActive = (path) => {
    return location.pathname === path ? "text-blue-500 font-bold" : "text-gray-900";
  };

  const menuItems = [
    { label: "Home", href: "/", exact: true },
    { label: "Explore more", href: "/Courses" },
    {
      label: "Courses",
      href: "/",
      dropdown: [
        { label: "IT", href: "/Informations" },
        { label: "Engineering", href: "/Engineering" },
        { label: "Nursing", href: "/Nursing" },
        { label: "Leadership", href: "/LeadershipandManagement" },
        { label: "Management", href: "/Management" },
        { label: "Health & Science", href: "/HealthandScience" },
        { label: "Hotel Management", href: "/HotelManagement" },
      ],
    },
    { label: "Destination", href: "/Destination" },
    { label: "FAQ", href: "/faq" },
    { label: "Contact", href: "/Contact_Us" },
    { label: "About", href: "/About_US" },
  ];

  return (
    <nav className="sticky top-0 z-50 py-3 bg-white shadow-2xl font-semibold dark:border-gray-900 dark:bg-gray-900 dark:text-white">
      <div className="flex justify-center md:justify-between items-center max-w-screen-xl mx-auto px-4">
        <Link to="/">
          <div className="md:h-20 md:w-auto rounded-sm overflow-hidden bg-white flex items-center justify-center dark:bg-gray-900">
            <img
              src={logo}
              className="h-20  md:h-full w-full object-contain p-1 sm:p-2"
              alt="Logo"
            />
          </div>
        </Link>
        <div className="flex items-center md:hidden">
          <button
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 text-[12px] text-gray-500 rounded-lg hover:text-ui-primary focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-dropdown"
            aria-expanded="false"
            onClick={handleNavList}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
        </div>
        <div
          className={`fixed top-0 left-0 w-full h-full bg-gray-50 dark:bg-gray-900 z-50 transition-transform transform ${
            showlist ? "translate-x-0" : "-translate-x-full"
          } md:relative md:top-auto md:left-auto md:w-auto md:h-auto md:bg-transparent md:translate-x-0`}
          id="navbar-dropdown"
        >
          <div className="flex justify-between items-center p-4 md:hidden">
            <span className="font-semibold text-lg">Menu</span>
            <button
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 text-[12px] text-gray-500 rounded-lg hover:text-ui-primary focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="navbar-dropdown"
              aria-expanded="false"
              onClick={handleNavList}
            >
              <span className="sr-only">Close main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1l15 15M1 16L16 1"
                />
              </svg>
            </button>
          </div>
          <ul className="flex flex-col py-6 px-2 md:p-0 mt-4 gap-3 text-md sm:text-[12px] rounded-lg bg-gray-50 md:flex-row md:space-x-5 md:mt-0 md:border-0 md:bg-transparent dark:bg-gray-900 md:dark:bg-transparent dark:border-gray-700 dark:text-white">
            {menuItems.map((item, index) => (
              <li key={index} className="relative">
                {item.dropdown ? (
                  <div className="relative text-center">
                    <button
                      ref={buttonRef}
                      onClick={handleDropdown}
                      className={`block py-2 px-3 md:px-3 xl:px-6 lg:py-2 hover:text-ui-primary rounded md:hover:text-ui-primary md:border-none md:p-0 dark:hover:text-white dark:text-white focus:border-none focus:ring-none ${
                        showDropdown ? "text-ui-primary" : "text-gray-900"
                      }`}
                    >
                      <span className="flex justify-center items-center text-md">
                        {item.label} &nbsp;
                        <DropdownIcon />
                      </span>
                    </button>
                    {showDropdown && (
                      <ul
                        ref={dropdownRef}
                        className="dropdown absolute left-1/2 transform -translate-x-1/2 top-full mt-2 w-full md:w-auto bg-white shadow-lg rounded-lg overflow-hidden md:min-w-[200px] md:max-w-[300px] z-50" // Adjusted z-index and positioning
                      >
                        {item.dropdown.map((dropdownItem, index) => (
                          <li key={index}>
                            <Link
                              to={dropdownItem.href}
                              className="block px-4 py-3 sm:rounded-lg text-md text-gray-800 hover:bg-gray-100 hover:text-ui-primary dark:text-white dark:hover:bg-gray-600 transition duration-300"
                              onClick={() => setShowDropdown(false)}
                            >
                              {dropdownItem.label}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ) : (
                  <Link
                    to={item.href}
                    className={`block py-2 border-b text-md border-gray-200 dark:border-gray-50 rounded-sm sm:border-none px-3 md:px-3 xl:px-6 lg:py-2 hover:text-ui-primary sm:rounded md:hover:text-ui-primary md:border-0 md:p-0 dark:hover:text-white dark:text-white ${isActive(
                      item.href
                    )}`}
                  >
                    {item.label}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

const DropdownIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    className="w-6 h-6 ml-1"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M19 9l-7 7-7-7"
    ></path>
  </svg>
);
