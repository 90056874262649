import "./Courses.css";
import React from "react";

import Nursing from "./Nursing";
import IT from "./IT";
import Hotel from "./Hotel";
import Health from "./Health";
import Leader from "./Leader";
import Management from "./Management";
import Engineering from "./Engineering";

const Courses = () => {
  return (
    <>
      <div
        class="event-details-area pb-150"
        style={{ backgroundColor: "#FFF", paddingTop: "100px" }}
      >
        <div class="container">
          <div class="row">
            <div class="col-md-8">
              <div class="event-d-top ">
                <div class="event-d-title text-justify">
                  <h3 style={{ fontWeight: "bold", color: "#0482f0" }}>
                    Choose the Best of Best Courses{" "}
                  </h3>
                  <span>
                    25 <span>Jan</span>
                  </span>
                </div>
                <div class="event-details-img">
                  <img src="./assets/img/event/l-1.jpg" alt="" />
                </div>
                <div class="registration-countdown">
                  <div class="timer">
                    <div data-countdown="2017/11/01" class="timer-grid"></div>
                  </div>
                </div>
              </div>

              <div class="b-details-text event-text">
                <div class="tab-text">
                  <div className=" text-justify">
                    <h1
                      style={{
                        color: "black",
                        paddingBottom: "20px",
                        paddingTop: "40px",
                        fontWeight: "bold",
                      }}
                    >
                      Courses We offers
                    </h1>
                    <p
                      style={{
                        color: "#000",
                        textAlign: "justify",
                        fontWeight: "400",
                        fontFamily: "sans-serif",
                        fontSize: "14px",
                      }}
                    >
                      We offers you the best courses for your higher study in
                      different countries. We offers a range of education degree
                      programs at the Bachelor’s, Master’s and Doctoral level,
                      from early childhood education to education
                      administration. Whether you're embarking on an entirely
                      new career path toward teacher licensure – or you're
                      looking to build upon your current skillset with
                      continuing education, you’ll get the support and resources
                      you’ll need. Your success is our goal – and through a
                      series of program assessments, we’ll ensure you're gaining
                      the skills and expertise you'll need to be an effective
                      educator. Earn your education degree online or on-campus –
                      and prepare for career opportunities for yourself.
                    </p>
                  </div>

                  <p></p>

                  {/* <div class="c-details-featue">
                                    <span><i class="fa fa-caret-right"></i>Vestibulum a lectus diam. Vestibulum venenatis rhoncus risus ut ultrices</span>
                                    <span><i class="fa fa-caret-right"></i>Nunc ex ex, vulputate vitae placerat sit amet, efficitur ut nulla.</span>
                                    <span><i class="fa fa-caret-right"></i>Etiam pellentesque, dui ut rutrum elementum, ligula risus porttitor duiss,</span>
                                </div> */}
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="ht-sidebar-wrapper">
                <div
                  class="ht-sidebar-widget widget-style-2"
                  style={{
                    backgroundColor: "#f9fafb",
                    boxShadow: " 0 0 10px 0 rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                  }}
                >
                  <h4 style={{ color: "#0482f0", fontWeight: "bold" }}>
                    Contact US
                  </h4>
                  <form class="question-form">
                    <input type="text" placeholder="Name*" />
                    <input type="email" placeholder="Email*" />
                    <input type="text" placeholder="Phone*" />
                    <input type="text" placeholder="StudentID*" />
                    <button
                      type="submit"
                      style={{ backgroundColor: "#0482f0", color: "white" }}
                    >
                      Contact US
                    </button>
                    <p style={{ color: "black" }}>
                      You must{" "}
                      <a href="/Courses" style={{ color: "#0482f0" }}>
                        login
                      </a>{" "}
                      to our site to book any courses event !!
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div
            className="object"
            style={{
              backgroundColor: "#FFF",
              paddingLeft: "0",
              paddingTop: "0",
              paddingBottom: "0px",
            }}
          ></div>

          <Engineering />
          <Health />
          <Nursing />
          <IT />
          <Hotel />
          <Health />
          <Leader />
          <Management />
        </div>
      </div>
    </>
  );
};
export default Courses;
