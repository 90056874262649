import React, { useEffect } from "react";
import $ from "jquery";
import "./Courses.css";
import hotelimg from "./hotel.jpg";

const Hotel = () => {
  useEffect(() => {
    $(document).ready(function () {
      $(window).scrollTop(0);
    });
  }, []);

  const data = {
    title: "Hotel Management",
    description: "Subedi & Associated provides an exceptional platform for individuals aspiring to delve into the intricacies of Hotel Management. This field encompasses a myriad of responsibilities, demanding adeptness in various domains. As a hotel manager, you are entrusted with the pivotal task of navigating through diverse challenges, fostering synergy across different departments, and upholding the establishment's reputation for excellence. In the realm of Hotel Management, proficiency extends beyond basic operational tasks; it requires a profound understanding of finance, strategic planning, impeccable service standards, and organizational acumen. As the helm of the hotel's operations, your role transcends mere oversight; it entails a comprehensive grasp of financial intricacies, meticulous planning, seamless service delivery, and effective organizational management. While you may have a dedicated team of managers to support you, the role of a hotel manager is inherently one of leadership. It necessitates the ability to inspire, motivate, and guide your team towards collective goals, fostering a culture of excellence and continuous improvement.",
    // Assuming the image is in the same directory
  };

  return (
    <>
     <div className="container mx-auto">
    
    <div className="row" >
      <div className="col-md-7">
        <div className="" >
          <div className="section-title text-justify text-black">
            <h3
              style={{ fontWeight: "bold", color: "#0482f0" }}
              className="section-heading"
            >
              {data.title}
            </h3>
            <p
              style={{
                color: "#000",
                textAlign: "justify",
                fontWeight: "400",
                fontFamily: "sans-serif",
                fontSize: "14px",
              }}
              className="section-description"
            >
              Subedi & Associated offers you the best platform for
              studying Hotel Management.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="row" style={{ paddingTop: "50px", paddingBottom: "50px" }}>
      <div className="col-lg-6">
      <div className="engineering-image-wrapper" >
        <img src={hotelimg} alt="Engineering" className="engineering-image" />
      </div>
      </div>
      <div className="col-lg-6 text-justify">
        <p
          style={{
            color: "black",
            textAlign: "justify",
            fontSize: "13px",
            fontWeight: "400",
          }}
          className="engineering-description"
        >
          {data.description}
        </p>
      </div>
    </div>
  </div>
    </>
  );
};

export default Hotel;
