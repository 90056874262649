import React, { useState, useEffect } from "react";
import Topheader from "../Header/Topheader";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Banner from "../Banner/Banner";
import Services from "../Services/Services";
import { Spinner } from "../spinner/spinner";
import Aboutus from "../About/Aboutus";

const ServicesRoute = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timerHandle = setTimeout(() => setIsLoading(false), 1500);

    // Cleanup function to clear timeout in case component unmounts before loading is done
    return () => clearTimeout(timerHandle);
  }, []);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <Topheader />
          <Header />
          <Banner info="Services" />
          <Services />
          <Aboutus />
          <Footer />
        </div>
      )}
    </>
  );
};

export default ServicesRoute;
