import React, { useState, useEffect } from "react";
import Topheader from "../Header/Topheader";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Banner from "../Banner/Banner";
import Engineering from "../Courses/Engineering";
import { Spinner } from "../spinner/spinner";
// Import your Spinner component

const EngineeringRoute = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulating loading delay
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    // Clear the timer on unmount to prevent memory leaks
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div>
        {isLoading ? (
          <Spinner /> // Display spinner while loading
        ) : (
          <>
            <Topheader />
            <Header />

            <Banner info="About us" />
            <div style={{ marginTop: "100px" , marginBottom:"100px"}}>
              <Engineering />
            </div>
            <Footer />
          </>
        )}
      </div>
    </>
  );
};

export default EngineeringRoute;
