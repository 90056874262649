import React from "react";
import un from "./uni.jpg";
import bb from "./bb.jpg";

const BlogDetails = () => {
  return (
    <div
      className="container my-5"
      style={{
        paddingTop: "50px",
        paddingBottom: "100px",
        aspectRatio: "16/9",
      }}
    >
      <div className="row">
        <div className="col-lg-12">
          <img
            src={un}
            alt="University"
            className="img-fluid rounded shadow"
            style={{
              height: "500px",
              width: "100%",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          />
        </div>
        <div className="col-lg-12 mt-4" style={{ paddingTop: "50px" }}>
          <p
            className="text-justify"
            style={{
              color: "#000",
              textAlign: "justify",
              fontWeight: "400",
              fontFamily: "sans-serif",
              fontSize: "14px",
            }}
          >
            <strong>
              Australia’s stunning natural scenery and sunny weather
            </strong>{" "}
            make it a top tourist destination, and our fresh local produce and
            numerous wine regions inspire chefs worldwide. With international
            travel on hold for the time being, there has been a boom in domestic
            tourism. The field of hospitality includes areas of study such as
            tourism management, ecotourism, culinary management, international
            tourism, food studies, and management of resorts, restaurants, and
            hotels. This month, we looked at which Australian universities with
            undergraduate degrees in humanities and social sciences performed
            best in overall experience, full-time employment, and skills
            development.
          </p>
          <p
            className="text-justify"
            style={{
              color: "#000",
              textAlign: "justify",
              fontWeight: "400",
              fontFamily: "sans-serif",
              fontSize: "14px",
            }}
          >
            <strong>Overall Experience:</strong> This rating looks at the
            proportion of students who were satisfied with the overall quality
            of their educational experience. Griffith University in Queensland
            ranked highest with a satisfaction rate of 92.5%, followed very
            closely by Australian Catholic University (ACU) with a satisfaction
            rate of 92%.
          </p>
          <p
            className="text-justify"
            style={{
              color: "#000",
              textAlign: "justify",
              fontWeight: "400",
              fontFamily: "sans-serif",
              fontSize: "14px",
            }}
          >
            <strong>Full-time Employment:</strong> This rating compares the
            employment rates of graduates of undergraduate degrees from
            different universities. It looks at the proportion of graduates who
            were employed full time four months after completing their
            course. Monash University in Victoria scored highest with an 84.6%
            full-time employment rate, followed by Southern Cross University,
            which scored 74.4%.
          </p>
          <p
            className="text-justify"
            style={{
              color: "#000",
              textAlign: "justify",
              fontWeight: "400",
              fontFamily: "sans-serif",
              fontSize: "14px",
            }}
          >
            <strong>Skills Development:</strong> This rating looks at the
            proportion of students who were satisfied with the skill development
            they experienced through their studies. The University of Notre Dame
            Australia took the top spot with a satisfaction rate of 90.6%,
            followed by Queensland’s Griffith University, which scored 89.7%.
          </p>
          <p
            className="text-justify"
            style={{
              color: "#000",
              textAlign: "justify",
              fontWeight: "400",
              fontFamily: "sans-serif",
              fontSize: "14px",
            }}
          >
            View all 2021 rankings for tourism and hospitality — filter by
            state, or level of study to view both undergraduate and postgraduate
            ratings. Further reading Search hospitality and tourism courses in
            Australia Search online hospitality and tourism courses in Australia
            The top-ranking Australian universities for 2021
          </p>
        </div>
      </div>
      <div
        className="row mt-5"
        style={{ paddingBottom: "100px", paddingTop: "50px" }}
      >
        <div className="col-lg-6">
          <img
            src={bb}
            alt="Books"
            className="img-fluid rounded shadow"
            style={{
              height: "500px",
              width: "100%",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          />
        </div>
        <div className="col-lg-6 mt-4">
          <p
            className="text-justify"
            style={{
              color: "#000",
              textAlign: "justify",
              fontWeight: "400",
              fontFamily: "sans-serif",
              fontSize: "14px",
            }}
          >
            <strong>Humanities and social sciences</strong> is one of the
            largest fields of study, and one of the most complicated. Many
            courses and specializations lead to a broad range of careers and
            further study options. You can combine humanities with law,
            education, engineering, or business, and many humanities graduates
            complete a postgraduate qualification to get a vocational edge. Some
            of the majors you can study in this field include ancient cultures,
            anthropology, counseling, information management, international
            studies, museum studies, philosophy, political science, sociology,
            and youth work.
          </p>
          <p
            className="text-justify"
            style={{
              color: "#000",
              textAlign: "justify",
              fontWeight: "400",
              fontFamily: "sans-serif",
              fontSize: "14px",
            }}
          >
            Our ratings look at specific strengths of Australian universities,
            giving insight to support and guide your decision-making when
            choosing a university. This month, we looked at which Australian
            universities with undergraduate degrees in humanities and social
            sciences performed best in graduate salary, teaching quality, and
            skills development.
          </p>

          <p
            style={{
              color: "#000",
              textAlign: "justify",
              fontWeight: "400",
              fontFamily: "sans-serif",
              fontSize: "14px",
            }}
          >
            Our ratings meticulously assess the distinctive strengths of
            Australian universities, offering valuable insights to aid in the
            decision-making process when selecting a university. In our latest
            evaluation, we scrutinized the performance of Australian
            universities offering undergraduate degrees in humanities and social
            sciences, focusing on key metrics such as graduate salary, teaching
            quality, and skills development.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
