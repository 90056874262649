// JapanDestination.jsx

import React from "react";
import "./destination.css"; // Add your CSS file for styling

// destinationData.js

import japanImage from "./japan.jpg"; // Replace with your actual image path

export const japanDestination = {
  name: "Japan",
  description: `Embarking on a journey to study abroad in Japan opens up a world of unparalleled experiences for Nepalese students. It's a seamless blend of cultural immersion and cutting-edge academic opportunities that redefine personal and professional growth. Japan's renowned universities stand as bastions of innovation and excellence, offering not just education but a doorway into global networks and advancements. From the bustling streets of Tokyo to the serene temples of Kyoto, every corner of Japan resonates with its rich cultural heritage, beckoning students to explore and learn. Studying here isn't just about acquiring knowledge; it's about embracing a way of life, adapting to new perspectives, and forging lifelong connections. Japan is more than a destination; it's a transformative experience that leaves an indelible mark on every student fortunate enough to pursue their dreams amidst its vibrant landscapes and inspiring ethos.`,
  sections: [
    {
      title: "Benefits of Studying in Japan",
      content: [
        "High-Quality Education System",
        "Cultural and Technological Advancements",
        "Opportunities for Global Networking",
      ],
    },
    {
      title: "Preparing for Study in Japan",
      content: [
        "Researching Universities and Programs",
        "Understanding Admission Requirements",
        "Applying for Scholarships and Financial Aid",
      ],
    },
    {
      title: "Language Requirements",
      content: [
        "Japanese Language Proficiency Requirements",
        "Language Learning Resources and Programs",
        "Importance of Language Skills",
      ],
    },
    {
      title: "Visa and Immigration Process",
      content: [
        "Obtaining the Necessary Student Visa",
        "Understanding Immigration Regulations",
        "Preparing Required Documents",
      ],
    },
    {
      title: "Living and Accommodation in Japan",
      content: [
        "Exploring Different Accommodation Options",
        "Understanding the Cost of Living",
        "Adapting to Japanese Lifestyle",
      ],
    },
    {
      title: "Education System in Japan",
      content: [
        "Overview of Japanese Education System",
        "Different Types of Universities and Colleges",
        "Available Academic Programs and Majors",
      ],
    },
  ],
  testimonials: [
    {
      name: "Sakshi Shrestha",
      comment:
        "Studying in Japan was a life-changing experience. I gained not only academic knowledge but also a deep appreciation for Japanese culture.",
    },
    {
      name: "Rajesh Singh",
      comment:
        "Rain Education provided excellent support throughout my journey to study in Japan. I am grateful for their guidance and assistance.",
    },
  ],
  visaSuccessStory: {
    title: "Visa Success Story",
    story:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
  careers: {
    title: "Careers",
    description:
      "Explore career opportunities available for students who have studied in Japan.",
    vacancyLink: "/careers", // Update with actual link
  },
  faq: {
    title: "FAQs - Study in Japan from Nepal",
    questions: [
      {
        question: "Can I study in Japan if I don't speak Japanese?",
        answer:
          "Yes, some universities offer programs taught in English. However, learning Japanese will enhance your experience and opportunities.",
      },
      {
        question: "What financial support options are available?",
        answer:
          "Various scholarships and part-time work opportunities are available for international students.",
      },
      // Add more questions as needed
    ],
  },
  events: {
    title: "Events",
    eventLink: "/events", // Update with actual link
  },
};

const JapanDestination = () => {
  return (
    <div className="py-12 mb-20">
      <div className="container mx-auto">
        <div className="row">
          <div className="col-md-12">
            <div className="section-wrapper">
              <div className="section-title text-justify text-black">
                <h3 style={{ fontWeight: "bold", color: "#0482f0" }}>
                  Destination
                </h3>
                <p
                  style={{
                    color: "#000",
                    textAlign: "justify",
                    fontWeight: "400",
                    fontFamily: "sans-serif",
                    fontSize: "14px",
                  }}
                >
                  Subedi & Associated provides you different destination for
                  your higher studies.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Destination Title and Description */}

        <div className="row">
          <div className="col-lg-6">
            <div className="destination-details-image ">
              <img
                src={japanImage}
                alt={japanDestination.name}
                className="rounded-lg shadow-lg mx-auto mb-4"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>
          </div>
          <div className="col-lg-6 text-center md:text-left">
            <h1
              style={{
                fontWeight: "bold",
                color: "black",
                paddingBottom: "20px",
                paddingTop: "20px",
              }}
              className="text-4xl font-bold text-blue-600 mb-4 text-center md:text-left"
            >
              {japanDestination.name}
            </h1>

            <p className="md:text-left text-center text-black"
              style={{
                color: "black",
                
                fontSize: "13px",
                fontWeight: "400",
              }}
            >
              {japanDestination.description}
            </p>
          </div>
        </div>
        {/* Main Content Sections */}
        <div className="">
          {japanDestination.sections.map((section, index) => (
            <div
              className="text-center sm:text-left pr-5 pl-5 text-black"
              style={{
                color: "black",
                paddingTop: "50px",
                fontWeight: "400",
              }}
              key={index}
            >
              <h1
                style={{
                  fontWeight: "bold",
                  color: "black",
                  paddingTop: "50px",
                  paddingBottom: "20px",
                  fontSize: "25px",
                }}
              >
                {section.title}
              </h1>
              <ul className="">
                {section.content.map((item, idx) => (
                  <li key={idx} className="text-gray-800 ">
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      &rarr; &nbsp; {item}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default JapanDestination;
