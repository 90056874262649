import React, { useState, useEffect } from "react";
import $ from "jquery";
import Topheader from "../Header/Topheader";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Banner from "../Banner/Banner";
import { Spinner } from "../spinner/spinner"; // Import the Spinner component
import Newz from "../Destination/newz";
const NewzRoute = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timerHandle = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    $(document).ready(function () {
      $(window).scrollTop(0);
    });

    return () => clearTimeout(timerHandle);
  }, []);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <Topheader />
          <Header />
          <Banner info="New Zealand" />

          <Newz />
          <Footer />
        </div>
      )}
    </>
  );
};

export default NewzRoute;
