import React from "react";

import healthImage from "./health.jpg";
import "./Courses.css";

const Health = () => {
  // Data for Health & Science
  const data = {
    title: "Health & Science",
    description: `Subedi & Associated offers you the best platform for studying Health & Science. Whether you're interested in becoming a Behavior analyst, Community health or public health worker, Doctor, Hearing aid specialist, Health educator, Mental health counselor, Patient care advocate, or Pharmacy sales representative, we provide comprehensive programs to help you achieve your career goals. Our partner universities in Australia, USA, Canada, New Zealand, and other countries offer a wide range of Diploma and Masters degree programs in health and science, covering areas such as medical sciences, public health, healthcare administration, and more. With state-of-the-art facilities, experienced faculty, and hands-on training opportunities, our programs ensure that you are well-prepared for a successful career in the healthcare industry.`,
  };

  return (
    <div className="container mx-auto">
      <div className="row">
        <div className="col-md-7">
          <div className="">
            <div className="section-title text-justify text-black">
              <h3
                style={{ fontWeight: "bold", color: "#0482f0" }}
                className="section-heading"
              >
                {data.title}
              </h3>
              <p
                style={{
                  color: "#000",
                  textAlign: "justify",
                  fontWeight: "400",
                  fontFamily: "sans-serif",
                  fontSize: "14px",
                }}
                className="section-description"
              >
                Subedi & Associated offers you the best platform for studying
                Health & Science.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row" style={{ paddingTop: "50px", paddingBottom: "50px" }}>
       
        <div className="col-lg-6 text-justify">
          <p
            style={{
              color: "black",
              textAlign: "justify",
              fontSize: "13px",
              fontWeight: "400",
            }}
            className="engineering-description"
          >
            {data.description}
          </p>
        </div>
        <div className="col-lg-6">
          <div className="engineering-image-wrapper">
            <img
              src={healthImage}
              alt="Engineering"
              className="engineering-image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Health;
