import React, { Component } from "react";
import $ from "jquery";
import usah from "./usa.jpg";

class usa extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    $(document).ready(function () {
      $(window).scrollTop(0);
    });
  }

  render() {
    return (
      <>
        <div className="">
          <div className="container">
            <div
              className="destination-details"
              style={{ paddingBottom: "50px" }}
            >
              <div className="row">
                <div className="col-md-12">
                  <div className="section-wrapper">
                    <div className="section-title text-justify text-black">
                      <h3 style={{ fontWeight: "bold", color: "#0482f0" }}>
                        Destination
                      </h3>
                      <p
                        style={{
                          color: "#000",
                          textAlign: "justify",
                          fontWeight: "400",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        Subedi & Associated provides you different destination
                        for your higher studies.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <div className="destination-details-image">
                    <img src={usah} alt="usaiamge" className="details-img" />
                  </div>
                </div>
                <div className="col-lg-6 text-justify">
                  <h1
                    style={{
                      fontWeight: "bold",
                      color: "black",
                      paddingBottom: "20px",
                      paddingTop: "20px",
                    }}
                  >
                    USA
                  </h1>
                  <p
                    style={{
                      color: "black",
                      textAlign: "justify",
                      fontSize: "13px",
                      fontWeight: "400",
                    }}
                  >
                    In most cases, individuals who wish to enter the United
                    States from a foreign country must obtain a visa, which
                    serves as authorization for either temporary or permanent
                    residency. For temporary stays, individuals typically apply
                    for nonimmigrant visas, while those seeking permanent
                    residence obtain immigrant visas. Among the various types of
                    visas available, students intending to study in the United
                    States must acquire a student visa. To pursue academic
                    studies in the United States, obtaining a student visa is a
                    prerequisite.
                    <br/> The specific type of student visa required
                    depends on the course of study and the type of educational
                    institution the applicant plans to attend. Generally,
                    students pursuing academic programs at accredited
                    universities, colleges, or language schools apply for an F
                    visa, while those enrolled in vocational or non-academic
                    programs apply for an M visa.
                  </p>
                </div>
              </div>

              <div
                className="text-left pt-5 pr-5 pl-5 text-black"
                style={{
                  color: "black",
                  paddingTop: "50px",
                  fontWeight: "400",
                }}
              >
                <h1
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    paddingTop: "50px",
                    paddingBottom: "20px",
                    fontSize: "25px",
                  }}
                >
                  Gather Required Documentation{" "}
                </h1>
                <ul>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; Passport valid for travel to the United
                      States – Your passport must be valid for at least six
                      months beyond your period of stay in the United States
                      (unless exempt by country-specific agreements). Each
                      individual who needs a visa must submit a separate
                      application, including any family members listed in your
                      passport.
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; Nonimmigrant Visa Application, Form DS-160
                      confirmation page.
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; Application fee payment receipt, if you are
                      required to pay before your interview.
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; Photo – You will upload your photo while
                      completing the online Form DS-160. If the photo upload
                      fails, you must bring one printed photo in the format
                      explained in the Photograph Requirements.
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; Certificate of Eligibility for Nonimmigrant
                      (F-1) Student Status-For Academic and Language Students,
                      Form I-20 or Certificate of Eligibility for Nonimmigrant
                      (M-1) Student Status for Vocational Students, Form I-20 –
                      Your school will send you a Form I-20 once they have
                      entered your information in the SEVIS database. You and
                      your school official must sign the Form I-20. All students
                      must be registered in the Student and Exchange Visitor
                      System (SEVIS). Your spouse and/or minor children, if they
                      intend live in the United States with you, will each
                      receive an individual Form I-20.
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; Your academic preparation, such as:
                      Transcripts, diplomas, degrees, or certificates from
                      schools you attended; and Standardized test scores
                      required by your U.S. school;
                    </p>
                  </li>

                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; Your intent to depart the United States upon
                      completion of the course of study; and How you will pay
                      all educational, living and travel costs.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default usa;
