import React, { useState } from "react";
import { PlusIcon } from "../svg/svg"; // Assuming PlusIcon is an SVG component you've defined elsewhere

const Faq = () => {
  const faqData = [
    {
      question: "Can a student be Sponsored by other than parents?",
      answer:
        "According to new visa rules only parents and sibling can be sponsor.",
    },
    {
      question: "Why should I study abroad?",
      answer:
        "Studying abroad is an adventure in so many ways, extending far beyond the classroom. You get to view the world with different angles and you get to learn about other cultures as well. This experience exposes you to new ways of thinking and living. By studying abroad you get a chance to learn a life lessons that can be never gained by just reading the academic text books. The student if interested can also fulfill their wish to travel to new places and experience different people and create a good bond with them.",
    },
    {
      question: "Do I need a referral?",
      answer:
        "At present, private as well as government sectors are looking for students with international exposures and experiences. This factor will contribute to the amplification of employability, international knowledge, resilience and flexibility in the part of students. These skills are important to employers and hence, the chances of employment for students with foreign degrees are high.",
    },
    {
      question: "When should I start planning to study abroad?",
      answer:
        "It’s better to plan as soon as possible if you are thinking about abroad study. There are innumerable options to choose from when it comes to studying abroad. You need to decide on the course, location, language, curriculum, costs and many other factors. Student should also keep in mind the aptitude and the language tests that are compulsory in most of the countries. These processes can be time consuming and the student should also keep in mind the legal aspects of the country that needs to be addressed mandatorily. So, it is suggestible that student start planning at least 7-8 months before the departure.",
    },
    {
      question: "Do I involve my parents in my decision to study abroad?",
      answer:
        "Yes. Studying abroad is a very big decision to take for any student. Your parents needs to be involved and they are bound to ask lots of questions. The student should not feel irritated but instead give them all the information they sought honestly. They must be clear on your motive to abroad study. Even after this, they show reluctance than our professional counselors can help the student to convince their parents either by face to face talk or through telephones, whichever is convenient.",
    },
    {
      question: "Is credit transfer possible?",
      answer:
        "Yes, but not in all the countries. The rules for credit transfer vary from country to country. Some will accept it and some might not. So, in order to be clear on this matter, first identify the country you want to go and the course you are willing to pursue. Then our counselors will guide you further and clear all the doubts you have.",
    },
    {
      question: "What is a passport?",
      answer:
        "Mandatory international form of identification required for showing a person’s nationality.",
    },
    {
      question: "What if I don’t have a passport?",
      answer:
        "Passport is must for studying abroad. Additionally, it is also required to secure the date of all the internationally recognized Aptitude as well as Language Tests. So, it is strongly advisable to obtain a passport beforehand.",
    },
    {
      question: "What is a visa and will I need one?",
      answer:
        "A visa is a stamp or other notation placed in your passport that will allow you to enter and temporarily reside in your host country during your program. A visa is required for all year and semester programs and for many of the summer programs.",
    },
    {
      question: "How do I apply for abroad study?",
      answer:
        "After you have made the decisions about the country and the course you are going to study, next comes the process of application submission. This is a vast area and your first step to study abroad. So after you have made your decision, please visit our office for further queries. Or, you can contact us through Facebook, Instagram or place a direct call on 01-4812049.",
    },
    {
      question:
        "What is the role of Intercraft Educational Consultancy Pvt.Ltd. in helping me with my abroad study?",
      answer:
        "We guide you in your quest to international education from top to bottom. Intercraft family in keenly dedicated to provide service of career counseling to the students of different educational level and background. From helping you to choose a correct college for your desired course to application procedure, we assist you with everything. We also help you provide information on the Visa Process and also the policy changes.",
    },
    {
      question:
        "What happens if I am not granted a visa for any particular country?",
      answer:
        "Unfortunately, these cases might occur as we have no control over Visa grant. This is solely issued by the consular officials. However, if the visa has been rejected in spite of all the required criteria’s fulfilled by the students, we at Intercraft will assist you with re-application of visa form. We will also help you to communicate with your university regarding deferral of your admission to the next session. Finally, we will assist you with all the changes that needs to be made in the initial documents.",
    },
    {
      question: "What happens when I arrive in my destination country?",
      answer:
        "First, you get off at the airport where you will be interrogated by custom officials regarding your purpose of visit, your visa status and also your address in the destination country. Then after claiming your luggage, you head towards your college or any accommodation that you might have booked in advance. Many colleges provide international students with airport pickup facility if they have decided to reside in college hostel. Otherwise you will need to hire a cab or train or any other means of transport.",
    },
    {
      question: "Who will support me there?",
      answer:
        "Your respective college/ university, alumni, volunteer association, legal advisor, information center will all be there to support you once to reach your destination country.",
    },
    {
      question: "Is there anything I won’t be able to do abroad?",
      answer:
        "Yes. All participants must be law abiding in their host country. There may be country-specific rules that apply. There also are limits on traveling independently. In a new environment, it’s important to be more aware than you might be at home. And remember: you are a student ambassador for the destination country, so it will be important to act respectfully toward others and to give extra attention to being culturally aware. Students may be sent home for breaking rules or laws, or for persistent behavioral issues.",
    },
    {
      question: "Can I come back to Nepal or other countries for holiday?",
      answer:
        "Yes you definitely can come back to Nepal during your holidays. You will be issued a multiple visa and it is your country after all. But, in case of other countries you will need to check the visa requirements of the country you want to visit and plan your trip accordingly.",
    },
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(index === openIndex ? null : index);
  };

  return (
    <div className="py-10 md:py-20">
      <div className="container mx-auto px-4">
        <div className="text-center mb-10 md:mb-20">
          <h2 className="text-5xl font-bold mb-4">Frequently Asked Questions</h2>
          <p className="text-xl">Find answers to the most common questions below.</p>
        </div>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
          {faqData.map((faq, index) => (
            <div key={index} className="bg-white text-gray-900 shadow-2xl rounded-lg p-6 transform transition duration-500 hover:scale-105">
              <details
                className={`mb-4 transition-all duration-300 ${openIndex === index ? 'open' : ''}`}
                open={openIndex === index}
                onClick={() => toggleAccordion(index)}
              >
                <summary className="flex items-center cursor-pointer text-xl font-semibold">
                  <PlusIcon className={`w-6 h-6 mr-2 transform transition-transform ${openIndex === index ? 'rotate-45' : ''}`} />
                  &nbsp;  {faq.question}
                </summary>
                <p className="mt-4 text-gray-700 leading-relaxed text-[12px] text-justify px-2">{faq.answer}</p>
              </details>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;
