import React from "react";
import "./Contact.css";
import contactimg from "./contact.png";
const Contact = () => {
  return (
    <div className="contact-area" style={{ marginTop: "100px" }}>
      <div className="container">
        <div className="">
          <div className="section-title text-justify text-black">
            <h3 style={{ fontWeight: "bold", color: "#0482f0 " }}>
              Contact Us
            </h3>
            <p
              style={{
                color: "#000",
                textAlign: "justify",
                fontWeight: "400",
                fontFamily: "sans-serif",
                fontSize: "14px",
              }}
            >
              {" "}
              Subedi and Associated is one of the most trusted educational
              consultancy.
            </p>
          </div>
        </div>
        <div className="contact-card">
          <div className="contact-content">
            <div className="row">
              <div className="col-lg-6">
                <div className="contact-text">
                  <h3 style={{ textAlign: "left" }}>
                    <span style={{ color: "#0482f0" }}>Masters </span>of
                    Consistency and{" "}
                    <span style={{ color: "#0482f0" }}>Quality</span>
                  </h3>
                </div>
                <div className="contact-image">
                  <img
                    src={contactimg}
                    alt="contact images"
                    style={{
                      width: "100%",
                      height: "400px",

                      objectFit: "fit",
                      borderRadius: "10px",
                      aspectRatio: "1/1",
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="contact-form-wrapper">
                  <form id="contact-form">
                    <div className="form-group">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Your Name"
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Your Email"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="message">Message</label>
                      <textarea
                        name="message"
                        id="message"
                        rows="4"
                        placeholder="Your Message"
                        className="form-control"
                      ></textarea>
                    </div>
                    <button type="submit" className="submit-btn">
                      Submit
                    </button>
                    <p className="form-message"></p>
                  </form>
                </div>
              </div>{" "}
              <div className="row">
                <div className="col-lg-12 ">
                  <div
                    className="contact-information"
                    style={{
                      textAlign: "center",
                      padding: "50px",
                      lineHeight: "30px",
                    }}
                  >
                    <h3
                      className=""
                      style={{
                        color: "#0482f0",
                        fontWeight: "bold",
                        fontSize: "20px",
                        marginBottom: "20px",
                        textAlign: "center",
                        textTransform: "uppercase",
                      }}
                    >
                      Contact us here
                    </h3>
                    <div className="contact-info-item">
                      <i
                        className="fa fa-home"
                        style={{
                          color: "#0482f0",
                          fontWeight: "bold",
                          fontSize: "20px",
                        }}
                      ></i>
                      <span
                        className="info-text"
                        style={{
                          fontWeight: "bold",
                          color: "black",
                          fontSize: "16px",
                          textTransform: "uppercase",
                        }}
                      >
                        Birtamod-4, Jhapa.
                      </span>
                    </div>
                    <div className="contact-info-item">
                      <i
                        className="fa fa-envelope-open"
                        style={{
                          color: "#0482f0",
                          fontWeight: "bold",
                          fontSize: "20px",
                        }}
                      ></i>
                      <span
                        className="info-text"
                        style={{
                          fontWeight: "bold",
                          color: "black",
                          fontSize: "16px",
                          textTransform: "uppercase",
                        }}
                      >
                        subedi.edu2018@gmail.com
                      </span>
                    </div>
                    <div className="contact-info-item">
                      <i
                        className="fa fa-phone"
                        style={{
                          color: "#0482f0",
                          fontWeight: "bold",
                          fontSize: "20px",
                        }}
                      ></i>
                      <span
                        className="info-text"
                        style={{
                          fontWeight: "bold",
                          color: "black",
                          fontSize: "16px",
                          textTransform: "uppercase",
                        }}
                      >
                        +977-023532220 & 9852642220
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
