import React, { useState, useEffect } from "react";
import Topheader from "../Header/Topheader";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import USA from "../Destination/usa";
import Banner from "../Banner/Banner";
import { Spinner } from "../spinner/spinner";

const USARoute = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => {
      setIsLoading(false);
      window.scrollTo(0, 0); // Scroll to top after loading
    }, 1500);

    // Cleanup function
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading ? (
        // Show loading spinner or message
        <Spinner />
      ) : (
        // Render content once loading is complete
        <div>
          <Topheader />
          <Header />
          <Banner info="USA" />
          <USA />
          <Footer />
        </div>
      )}
    </>
  );
};

export default USARoute;
