import React, { useState, useEffect } from "react";
import $ from "jquery";
import Topheader from "../Header/Topheader";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Australia from "../Destination/Australia";
import Banner from "../Banner/Banner";
import { Spinner } from "../spinner/spinner";

const AustraliaRoute = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timerHandle = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    $(document).ready(function () {
      $(window).scrollTop(0);
    });

    return () => clearTimeout(timerHandle);
  }, []);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ): (
        <div>
        <Topheader />
        <Header />
        <Banner info="Australia" />
         <Australia />
        <Footer />
      </div>  
      )
      
      }
    </>
  );
};

export default AustraliaRoute;
