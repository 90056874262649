import React from "react";
import "./Objective.css";
import ab from "./objective.png";

const Objective = () => {
  return (
    <div className="objective-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-wrapper">
              <div className="section-title text-justify text-black">
                <h3 style={{ fontWeight: "bold", color: "#0482f0" }}>
                  Objectives
                </h3>
                <p
                  style={{
                    color: "#000",
                    textAlign: "justify",
                    fontWeight: "400",
                    fontFamily: "sans-serif",
                    fontSize: "14px",
                  }}
                >
                  Subedi & Associated aims to provide the right information to
                  students
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <img className="objective-image" src={ab} alt="Objective" />
          </div>
          <div className="col-lg-6">
            <div className="objective-content">
              <p>
                " The main objective of our business is to be the preferred
                student recruitment consultancy in the targeted market through
                honest information to our clients. Subedi and Associated aims to
                achieve the mission and vision through the following
                objectives:"
              </p>
            </div>
          </div>
        </div>
        <div className="objective-list" style={{ marginTop: "20px", marginBottom: "50px" , padding:"20px"}}>
          <ul>
            <li>
              To achieve and surpass norms established by internationally
              reputed regulatory or certifying agencies and qualify to provide
              recruitment services.
            </li>
            <li>
              High ethical standards in business and professions, the
              recognition of the worthiness of all useful occupations, and the
              dignifying of each Rotaractors's occupation as an opportunity to
              serve society.
            </li>
            <li>
              To obtain official representation status from internationally
              renowned Institutions and introduce them to students, thereby
              facilitating the students to enroll in their desired programs
              while aiding the cultural diversity objectives of institutions.
            </li>
            <li>
              To actively participate in the marketing plans of principal
              foreign Institutions by conducting events and providing
              infrastructure and administrative facilities as well as supplying
              market intelligence.
            </li>
            <li>
              To be the preferred student recruitment consultancy in the
              targeted market through honest information to our clients.
            </li>
            <li>
              To provide the right information of each course and organization
              to the students out of which they can choose or in their words we
              guide them to choose the right course/level and organization of
              their study.
            </li>
            <li>
              To provide comprehensive solutions for searching programs,
              identifying Institutions, improving language proficiency,
              facilitating applications, scholarships & funding, visa
              assistance, pre-departure preparations, accommodations and
              post-landing under the same roof.
            </li>
            <li>
              Maintaining relationships with family post recruitment and taking
              feedback from students for benefiting future students.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Objective;
