import React, { Component } from "react";
import "./Footer.css";
import log from "./subedi.png";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <footer className="footer_area mt-0">
          <div className="container">
            <div className="footer_row row text-justify">
              <div className="col-lg-3 col-md-6 col-sm-6 footer_about ">
                <h2>
                  Subedi & Associated
                  <br />
                  Pvt.ltd{" "}
                </h2>
                <div className="ml-4">
                  <img src={log} alt="" style={{ height: "100px" }} />
                </div>
                <ul className="socail_icon">
                  <li>
                    <a href="/" target="_blank" rel="noopener noreferrer">
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                      <span> </span>
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fa fa-twitter" aria-hidden="true"></i>{" "}
                      <span> </span>
                    </a>
                  </li>
                  <li>
                    <a href="mailto:subedi.edu2018@gmail.com">
                      <i className="fa fa-google-plus" aria-hidden="true"></i>{" "}
                      <span> </span>
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fa fa-linkedin" aria-hidden="true"></i>{" "}
                      <span> </span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 footer_about quick">
                <h2>Quick links</h2>
                <ul className="quick_link">
                  <li>
                    <a href="/Blog">
                      <i className="fa fa-chevron-right"></i>Blogs
                    </a>
                  </li>
                  <li>
                    <a href="/Services">
                      <i className="fa fa-chevron-right"></i>Services
                    </a>
                  </li>
                  <li>
                    <a href="/Destination">
                      <i className="fa fa-chevron-right"></i>Destinations
                    </a>
                  </li>
                  <li>
                    <a href="/Objective">
                      <i className="fa fa-chevron-right"></i>Objectives
                    </a>
                  </li>
                  <li>
                    <a href="/Board">
                      <i className="fa fa-chevron-right"></i>Message from CEO
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 footer_about quick">
                <h2>Quick links</h2>
                <ul className="quick_link">
                  <li>
                    <a href="/FAQ">
                      <i className="fa fa-chevron-right"></i>FAQ
                    </a>
                  </li>
                  
                  <li>
                    <a href="/Courses">
                      <i className="fa fa-chevron-right"></i>Courses
                    </a>
                  </li>
                  <li>
                    <a href="/About_Us">
                      <i className="fa fa-chevron-right"></i>About-US
                    </a>
                  </li>
                  <li>
                    <a href="/Contact_US">
                      <i className="fa fa-chevron-right"></i>Contact-US
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 footer_about">
                <h2>CONTACT US</h2>
                <address>
                  <ul className="my_address">
                    <li>
                      <a href="mailto:subedi.edu2018@gmail.com">
                        <i className="fa fa-envelope" aria-hidden="true"></i>
                        subedi.edu2018@gmail.com
                      </a>
                    </li>
                    <li>
                      <a href="tel:+977-9852642220">
                        <i className="fa fa-phone" aria-hidden="true"></i>
                        023532220,+977-9852642220 , 9820542220
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.google.com/maps/place/26%C2%B038'27.7%22N+87%C2%B059'28.1%22E/@26.6413377,87.9904113,18.91z/data=!4m4!3m3!8m2!3d26.641014!4d87.991144?entry=ttu"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                        Birtamod-4,Jhapa
                      </a>
                    </li>
                  </ul>
                </address>
              </div>
            </div>
          </div>
          <div className="copyright_area">
            Copyright 2020 All rights reserved. Designed by{" "}
            <a href="/" style={{ color: "#0482f0" }}>
              Subedi & Associated Pvt.Ltd
            </a>
            {/* <a href="https://fibonacciengineeringanditsolutions.com"  target="_blank" rel="noopener noreferrer">Fibonacci Engineering & IT Solutions Pvt.Ltd</a> */}
          </div>
        </footer>
      </>
    );
  }
}
export default Footer;
