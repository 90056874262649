import React, { useState, useEffect } from "react";
import $ from "jquery";
import Topheader from "../Header/Topheader";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import AboutPage from "../About/AboutPage";
import Banner from "../Banner/Banner";
import Testomonial from "../Testomonial/Testomonial";
import { Spinner } from "../spinner/spinner";
import About from "../About/About";

const AboutRoute = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timerHandle = setTimeout(() => setIsLoading(false), 1000);
    $(document).ready(function () {
      $(this).scrollTop(0);
    });

    // Cleanup function to clear timeout in case component unmounts before loading is done
    return () => clearTimeout(timerHandle);
  }, []);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <Topheader />
          <Header />
          <Banner info="About us" />
          <AboutPage />
          
          <About />
          
          <Testomonial />
          <Footer />
        </div>
      )}
    </>
  );
};

export default AboutRoute;
