import React from "react";

import nurseImage from "./nurse.jpg";
import "./Courses.css";

const Nursing = () => {
  const data = {
    title: "Nursing",
    description: `Subedi & Associated provides unparalleled access to premier nursing universities across Australia, the USA, Canada, New Zealand, and various other countries. Whether you're pursuing a Diploma or Masters in Nursing, our extensive network of institutions ensures that you receive top-tier education tailored to your career aspirations. We understand that quality education is the cornerstone of a successful nursing career. That's why our programs are meticulously designed to equip you with the necessary skills and knowledge to excel as a Registered Nurse (RN). From fostering a deep understanding of medical theory to honing practical skills through hands-on experience, our curriculum is crafted to nurture well-trained healthcare professionals. Our curriculum is thoughtfully crafted to encompass both theoretical learning and practical application, providing you with a well-rounded education that prepares you to meet the demands of modern healthcare. From mastering medical theory to developing proficiency in clinical skills through hands-on experience, our programs foster the growth and development of competent and compassionate healthcare professionals.Join us as we embark on this transformative journey towards becoming skilled and empowered nursing professionals.`,
  };

  return (
    <div className="container mx-auto">
      <div className="row">
        <div className="col-md-7">
          <div className="">
            <div className="section-title text-justify text-black">
              <h3
                style={{ fontWeight: "bold", color: "#0482f0" }}
                className="section-heading"
              >
                {data.title}
              </h3>
              <p
                style={{
                  color: "#000",
                  textAlign: "justify",
                  fontWeight: "400",
                  fontFamily: "sans-serif",
                  fontSize: "14px",
                }}
                className="section-description"
              >
                Subedi & Associated offers you the best platform for studying
                Nursing.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="row"
        style={{ paddingTop: "50px", paddingBottom: "50px" }}
      >
        <div className="col-lg-6">
          <div className="engineering-image-wrapper">
            <img
              src={nurseImage}
              alt="Engineering"
              className="engineering-image"
            />
          </div>
        </div>
        <div className="col-lg-6 text-justify">
          <p
            style={{
              color: "black",
              textAlign: "justify",
              fontSize: "13px",
              fontWeight: "400",
            }}
            className="engineering-description"
          >
            {data.description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Nursing;
