import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { MdWork } from "react-icons/md";
import sajal from "./sajal.jpg";
import gautam from "./gautam.jpg";
import "./Testomonial.css";

const Testimonial = () => {
  const testimonialData = [
    {
      name: "Santosh Regmi",
      date: "19 Feb",
      occupation: "Student",
      image: sajal,
      feedback:
        "Subedi & Associated is the best educational consultancy I have ever seen. It is very popular and works very finely.",
    },
    {
      name: "Gautam Budhathoki",
      date: "01 Sep",
      occupation: "Software Engineer",
      image: gautam,
      feedback:
        "I have had a good experience with Subedi & Associated. It helped me a lot during my brother's visa process.",
    },
  ];

  const carouselOptions = {
    responsive: {
      0: { items: 1 },
      450: { items: 1 },
      600: { items: 1 },
      1000: { items: 2 },
    },
    nav: true,
    dots: true,
    loop: true,
    infinite: false,
  };

  return (
    <div className="container">
      <section className="event-area " style={{ paddingTop: "30px" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-8 col-sm-10">
              <div className="section-wrapper">
                <div className="section-title style-2 text-black text-justify">
                  <h3 style={{ fontWeight: "bold", color: "#0482f0" }}>
                    Testimonial
                  </h3>
                  <p>Let's know what our clients say</p>
                </div>
              </div>
            </div>

            <div className="" style={{ marginTop: "0px", paddingTop: "200px" }}>
              <OwlCarousel {...carouselOptions} style={{ height: "500px" }}>
                {testimonialData.map((testimonial, index) => (
                  <div key={index} className="col-xs-12">
                    <div
                      className="testomonial-box"
                      style={{
                        backgroundColor: "white",

                        padding: "20px",
                      }}
                    >
                      <div className="ht-event-image">
                        <a href="/#">
                          <img
                            style={{
                              width: "100px",
                              height: "100px",
                              borderRadius: "50%",
                            }}
                            src={testimonial.image}
                            alt=""
                            className="testimonial-image"
                          />
                        </a>
                      </div>
                      <div className="ht-event-text">
                        <div className="ht-event-meta">
                          <span className="event-date">{testimonial.date}</span>
                          <div className="event-title">
                            <h4>
                              <a href="event.html">{testimonial.name}</a>
                            </h4>
                            <span>
                              <i className="">
                                <MdWork />
                              </i>
                              {testimonial.occupation}
                            </span>
                          </div>
                        </div>
                        <p
                          style={{
                            color: "black",
                            textAlign: "justify",
                            fontSize: "13px",
                            fontWeight: "400",
                            paddingTop: "10px",
                          }}
                          className="testimonial-feedback"
                        >
                          {testimonial.feedback}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Testimonial;
