import React, { useState, useEffect } from "react";
import Topheader from "../Header/Topheader";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Banner from "../Banner/Banner";
import Management from "../Courses/Management";
import { Spinner } from "../spinner/spinner";

const ManagementRoute = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulating loading delay
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    // Clear the timer on unmount to prevent memory leaks
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <Topheader />
            <Header />
            <Banner info="Management" />
            <div style={{ marginTop: "100px" , marginBottom:"100px"}}>
            <Management />
            </div>
            <Footer />
           
          </>
        )}
      </div>
    </>
  );
};

export default ManagementRoute;
