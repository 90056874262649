import React from "react";
import $ from "jquery";
import leaderImage from "./leader.jpg";
import "./Courses.css";

const Leader = () => {
  $(document).ready(function () {
    $(window).scrollTop(0);
  });

  const data = {
    title: "Leadership and Management",
    description: `Subedi & Associated provides an unparalleled platform for individuals seeking to delve into the realms of Leadership and Management. Our Global Leaders Program stands as a beacon of empowerment for cultural changemakers, facilitating the cultivation of organizations poised to make a tangible difference in communities worldwide. Through a meticulously crafted twelve-month Executive Graduate Certificate program, participants are equipped with the multifaceted skills and insights necessary to navigate diverse landscapes of Social Entrepreneurship, Community Development, Business Management, Cultural Agency, and Teaching Artistry. This innovative initiative not only fosters personal and professional growth but also fosters the development of leaders who are catalysts for positive change on a global scale. Through a blend of rigorous coursework, practical experiences, and mentorship opportunities, participants embark on a transformative learning journey that transcends traditional boundaries. Our program fosters not only personal and professional growth but also nurtures the emergence of visionary leaders capable of effecting positive change on a global scale.

    Join us as we embark on this journey of exploration, discovery, and empowerment. Together, let us chart a course towards a future where leadership excellence and social impact converge to create a brighter tomorrow for all.`,
  };

  return (
    <div className="container mx-auto">
      <div className="row">
        <div className="col-md-7">
          <div className="">
            <div className="section-title text-justify text-black">
              <h3
                style={{ fontWeight: "bold", color: "#0482f0" }}
                className="section-heading"
              >
                {data.title}
              </h3>
              <p
                style={{
                  color: "#000",
                  textAlign: "justify",
                  fontWeight: "400",
                  fontFamily: "sans-serif",
                  fontSize: "14px",
                }}
                className="section-description"
              >
                Subedi & Associated offers you the best platform for studying
                Leadership and Management.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="row"
        style={{ paddingTop: "50px", paddingBottom: "50px" }}
      >
        <div className="col-lg-6">
          <div className="engineering-image-wrapper">
            <img
              src={leaderImage}
              alt="Engineering"
              className="engineering-image"
            />
          </div>
        </div>
        <div className="col-lg-6 text-justify">
          <p
            style={{
              color: "black",
              textAlign: "justify",
              fontSize: "13px",
              fontWeight: "400",
            }}
            className="engineering-description"
          >
            {data.description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Leader;
