import React, { useState, useEffect } from "react";
import Topheader from "../Header/Topheader";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Banner from "../Banner/Banner";
import IT from "../Courses/IT";
import { Spinner } from "../spinner/spinner";

const ITRoute = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulating loading delay
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <Topheader />
          <Header />
          <Banner info="Information Technology" />

          <div style={{ marginTop: "100px" , marginBottom:"100px"}}>
            <IT />
          </div>
           
            <Footer />
         
        </div>
      )}
    </>
  );
};

export default ITRoute;
