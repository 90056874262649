import React, { Component } from "react";
import $ from "jquery";
import newzh from "./new.jpg";

class newz extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    $(document).ready(function () {
      $(window).scrollTop(0);
    });
  }

  render() {
    return (
      <>
        <div className="">
          <div className="container">
            <div className="destination-details">
              <div className="row">
                <div className="col-md-12">
                  <div className="section-wrapper">
                    <div className="section-title text-justify text-black">
                      <h3 style={{ fontWeight: "bold", color: "#0482f0" }}>
                        Destination
                      </h3>
                      <p style={{ fontWeight: "400" }}>
                        Subedi & Associated provides you different destination
                        for your higher studies.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <img src={newzh} alt="" />
                </div>
                <div className="col-lg-6 text-justify">
                  <h1
                    style={{
                      fontWeight: "bold",
                      color: "black",
                      paddingBottom: "20px",
                      paddingTop: "20px",
                    }}
                  >
                    New Zealand
                  </h1>
                  <p
                    style={{
                      color: "black",
                      textAlign: "justify",
                      fontSize: "13px",
                      fontWeight: "400",
                    }}
                  >
                    For individuals with an adventurous spirit and a thirst for
                    exploration, New Zealand presents itself as an ideal study
                    destination. Nestled amidst breathtaking natural landscapes,
                    characterized by rugged mountains, pristine beaches, and
                    lush greenery, New Zealand offers an unparalleled backdrop
                    for academic pursuits. Beyond its picturesque scenery, the
                    country is renowned for its warm and welcoming culture, with
                    locals known for their friendliness and hospitality. Coupled
                    with world-class universities and institutions, New Zealand
                    stands as a beacon of excellence in higher education.
                    <br />
                    Currently, New Zealand hosts over 106,000 international
                    students who have chosen to pursue their academic dreams in
                    this captivating country. If you're considering joining
                    their ranks, it's essential to understand the financial
                    aspects of studying in New Zealand.
                  </p>
                </div>
              </div>
              <div className="text-justify" style={{ marginTop: "20px" }}>
                <p
                  style={{
                    color: "black",
                    textAlign: "justify",
                    fontSize: "13px",
                    fontWeight: "400",
                  }}
                >
                  <strong>New Zealand</strong> study abroad programs will expose
                  students to an amazing variety of breathtaking landscapes.It
                  is true – New Zealand’s unbelievable landscapes are very much
                  what makes the country so unique and memorable. There are a
                  wide variety of opportunities to view wildlife like dolphins
                  and whales, as well as plenty of outdoor activities like white
                  water rafting and bungee jumping. But there is more to New
                  Zealand than its natural beauty. Students studying abroad in
                  New Zealand will be captivated by this interesting and remote
                  country.
                  <br />
                  <br />
                  <strong>New Zealand</strong>, a beautiful and unique country
                  in the South Pacific, where exciting things are happening in
                  Education. New Zealand is becoming the first choice for an
                  increasing number of international students seeking quality
                  education & to explore new ideas in pioneering programmes that
                  will put them at the front in the world job markets of the
                  future.New Zealand universities, colleges and other New
                  Zealand education institutions offer students a unique
                  learning environment. This is supported by an integrated
                  education system in which students attending New Zealand
                  schools find it easy to make the transition to the next level
                  of education
                </p>
              </div>

              <div
                className="text-left pt-5 pr-5 pl-5 text-black"
                style={{
                  color: "black",
                  paddingTop: "50px",
                  fontWeight: "400",
                }}
              >
                <h1
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    fontSize: "25px",
                  }}
                >
                  What are the requirements ?
                </h1>
                <ul>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; Successful enrolment with a registered
                      course
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; Meet the Genuine Temporary Entrant
                      requirement
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; Sufficient funds for airfares, course fees
                      and living costs
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; English language proficiency
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; Meet health and character requirements
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; Appropriate Health Insurance- Overseas
                      Student Health Cover (OSHC)
                    </p>
                  </li>
                </ul>
              </div>

              <div
                className="text-left pt-5 pr-5 pl-5 text-black"
                style={{
                  color: "black",
                  paddingTop: "20px",
                  fontWeight: "400",
                }}
              >
                <h1
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    paddingTop: "30px",
                    paddingBottom: "20px",
                    fontSize: "25px",
                  }}
                >
                  Other Required Documents
                </h1>
                <ul>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; A completed application form.
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; Certified copies of all the pages including
                      the biographical pages of the current passports or travel
                      documents of all people included in the application.
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; If your name has changed or the name of
                      anyone included in your application has changed: a
                      certified copy of evidence of the name change.
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; Certified copies of birth certificates for
                      you and any other person included in the application. The
                      certificates must show the names of both parents of each
                      person. 2 MRP passport Sized Photos
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; Certified copies of marriage certificates or
                      relationship registrations for you and anyone else
                      included in your application, even if they are not joining
                      you in Australia.
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; If you are living in a de facto
                      relationship: independent evidence that you have been in
                      the relationship for at least 12 months (for example,
                      joint bank account statements or billing accounts in joint
                      names).
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; If you or anyone included in the application
                      has been widowed, divorced or permanently separated: a
                      certified copy of the death certificate, divorce degree
                      absolute, or statutory declaration/separation certificate.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default newz;
