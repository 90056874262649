import React, { Component } from "react";
import $ from "jquery";
import ab from "./aus.jpg";
import "./destination.css";

class Australia extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    $(document).ready(function () {
      $(window).scrollTop(0);
    });
  }

  render() {
    return (
      <>
        <div className="">
          <div className="container">
            <div className="destination-details ">
              <div className="row">
                <div className="col-md-12">
                  <div className="section-wrapper">
                    <div className="section-title text-justify text-black">
                      <h3 style={{ fontWeight: "bold", color: "#0482f0" }}>
                        Destination
                      </h3>
                      <p
                        style={{
                          color: "#000",
                          textAlign: "justify",
                          fontWeight: "400",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        Subedi & Associated provides you different destination
                        for your higher studies.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <div className="destination-details-image ">
                    <img src={ab} alt="" />
                  </div>
                </div>

                <div className="col-lg-6 text-justify">
                  <h1
                    style={{
                      fontWeight: "bold",
                      color: "black",
                      paddingBottom: "20px",
                      paddingTop: "20px",
                    }}
                  >
                    Australia
                  </h1>
                  <p
                    style={{
                      color: "black",
                      textAlign: "justify",
                      fontSize: "13px",
                      fontWeight: "400",
                    }}
                  >
                    Australia is widely recognized as a premier destination for
                    higher education. Renowned for its world-class universities
                    and innovative academic programs, studying in Australia
                    offers numerous benefits. Apart from acquiring invaluable
                    knowledge through its esteemed education system, students
                    also gain a rich cultural experience that fosters personal
                    growth and global understanding.
                    <br />
                    <br />
                    Moreover, studying in Australia presents an excellent
                    opportunity to enhance English language proficiency, as
                    English is the primary language of instruction. This
                    language proficiency is not only essential for academic
                    success but also highly valued in today's global job market.
                    One of the distinctive advantages of studying in Australia
                    is the ability to work legally while pursuing your
                    education.
                  </p>
                </div>
              </div>

              <div className="  text-left text-black pr-5 pl-5">
                <h1
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    paddingTop: "50px",
                    paddingBottom: "20px",
                    fontSize: "25px",
                  }}
                >
                  What are the requirements for Australia ?
                </h1>
                <ul>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; Successful enrolment with a registered
                      course
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; Meet the Genuine Temporary Entrant
                      requirement
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; Sufficient funds for airfares, course fees
                      and living costs
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; English language proficiency
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; Meet health and character requirements
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; Appropriate Health Insurance- Overseas
                      Student Health Cover (OSHC)
                    </p>
                  </li>
                </ul>
              </div>

              <div
                className="text-left pt-5 pr-5 pl-5 text-black"
                style={{
                  color: "black",
                  paddingTop: "50px",
                  fontWeight: "400",
                  paddingBottom: "50px",
                }}
              >
                <h1
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    paddingBottom: "20px",
                    fontSize: "25px",
                  }}
                >
                  Other Required Documents
                </h1>
                <ul>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; A completed application form.
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; Certified copies of all the pages including
                      the biographical pages of the current passports or travel
                      documents of all people included in the application.
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; If your name has changed or the name of
                      anyone included in your application has changed: a
                      certified copy of evidence of the name change.
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; Certified copies of birth certificates for
                      you and any other person included in the application. The
                      certificates must show the names of both parents of each
                      person. 2 MRP passport Sized Photos
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; Certified copies of marriage certificates or
                      relationship registrations for you and anyone else
                      included in your application, even if they are not joining
                      you in Australia.
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; If you are living in a de facto
                      relationship: independent evidence that you have been in
                      the relationship for at least 12 months (for example,
                      joint bank account statements or billing accounts in joint
                      names).
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; If you or anyone included in the application
                      has been widowed, divorced or permanently separated: a
                      certified copy of the death certificate, divorce decree
                      absolute, or statutory declaration/separation certificate.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Australia;
