import dubaiImage from "./dubai.jpeg"; // Dubai image
import React from "react";
import "./destination.css"; // Include your CSS file for additional styling

export const destinations = [
  {
    name: "Dubai",
    description: `
      Dubai, situated as one of the seven emirates comprising the United Arab Emirates, presents Nepalese students with a captivating blend of modernity and tradition. Renowned globally for its striking skyline adorned with iconic skyscrapers like the Burj Khalifa, Dubai is a city where innovation meets cultural richness. Its vibrant cultural tapestry, deeply rooted in Arabic heritage, offers a unique backdrop for students seeking not just academic excellence but also a profound cultural immersion.

Education in Dubai is characterized by its state-of-the-art infrastructure and a diverse range of institutions offering world-class programs across various disciplines. From engineering to business, hospitality to technology, Dubai's universities cater to diverse academic interests with a commitment to excellence and innovation. Moreover, the city's strategic location as a global hub facilitates unparalleled networking opportunities and exposure to international perspectives.

Beyond academics, Dubai's dynamic lifestyle beckons students to explore its thriving arts scene, bustling markets, and pristine beaches. The city's commitment to sustainability and development is evident in its futuristic projects and eco-friendly initiatives, providing students with a forward-looking environment to shape their future careers.`,
    requirements: [
      "Valid passport with at least 6 months validity.",
      "Recent passport-sized photographs with a white background.",
      "Admission offer letter from a recognized educational institution in Dubai.",
      "Proof of sufficient funds (bank statements).",
      "Tenancy agreement or university accommodation confirmation letter.",
      "Copy of tuition fee receipt.",
      "Copy of visa fee receipt.",
      "Medical test results (including HIV, Hepatitis C, Hepatitis B, Leprosy, Syphilis, and Tuberculosis).",
    ],
    otherDocuments: [
      "Passport copies.",
      "Undergraduate degree proof (for post-graduate students).",
      "Embassy verification of academic transcripts and certificates.",
      "Character certificate from high school and SEE.",
      "Proof of student and parents' citizenship documents (translated and notarized).",
      "Notarized copies of IELTS certificate and passport.",
    ],
    image: dubaiImage,
  },
];

const DubaiDestinationDetails = () => {
  return (
    <>
      {destinations.map((destination, index) => (
        <div key={index} className="py-12 mb-20">
          <div className="container mx-auto">
            <div className="row">
              <div className="col-md-12">
                <div className="section-wrapper">
                  <div className="section-title text-justify text-black">
                    <h3 style={{ fontWeight: "bold", color: "#0482f0" }}>
                      Destination
                    </h3>
                    <p
                      style={{
                        color: "#000",
                        textAlign: "justify",
                        fontWeight: "400",
                        fontFamily: "sans-serif",
                        fontSize: "14px",
                      }}
                    >
                      Subedi & Associated provides you different destination for
                      your higher studies.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="destination-details-image">
                  <img
                    src={destination.image}
                    alt={destination.name}
                    className="rounded-lg shadow-lg mx-auto mb-4"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </div>
              </div>

              <div className="col-lg-6 text-center md:text-left">
              <h1
                    style={{
                      fontWeight: "bold",
                      color: "black",
                      paddingBottom: "20px",
                      paddingTop: "20px",
                    }}
                  >
                  {destination.name}
                </h1>
                <p
                    style={{
                      color: "black",
                      textAlign: "justify",
                      fontSize: "13px",
                      fontWeight: "400",
                    }}
                  >
                    {destination.description}
                  </p>
              </div>
            </div>

            <div className="mt-12">
              <div className="text-justify pt-5 pr-5 pl-5 text-black">
                <h1
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    paddingTop: "50px",
                    paddingBottom: "20px",
                    fontSize: "25px",
                  }}
                >
                  What are the requirements?
                </h1>
                <ul className="">
                  {destination.requirements.map((requirement, reqIndex) => (
                    <li key={reqIndex} className="text-gray-800">
                      <p
                        style={{
                          color: "black",
                          textAlign: "justify",
                          fontSize: "13px",
                          fontWeight: "400",
                        }}
                      >
                        &rarr; &nbsp; {requirement}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="text-justify pt-5 pr-5 pl-5 text-black">
                <h1
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    paddingTop: "50px",
                    paddingBottom: "20px",
                    fontSize: "25px",
                  }}
                >
                  Other Required Documents
                </h1>
                <ul className="">
                  {destination.otherDocuments.map((document, docIndex) => (
                    <li key={docIndex} className="text-gray-800">
                      <p
                        style={{
                          color: "black",
                          textAlign: "justify",
                          fontSize: "13px",
                          fontWeight: "400",
                        }}
                      >
                        &rarr; &nbsp; {document}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default DubaiDestinationDetails;
