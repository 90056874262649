import React, { useState, useEffect } from "react";
import Topheader from "../Header/Topheader";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Objective from "../Objectives/Objective";
import Banner from "../Banner/Banner";
import { Spinner } from "../spinner/spinner";

const ObjectiveRoute = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <Topheader />
          <Header />
          <Banner info="Objectives" />

          <Objective />

          <Footer />
        </div>
      )}
    </>
  );
};

export default ObjectiveRoute;
