import React, { useState, useEffect } from "react";
import Topheader from "../Header/Topheader";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Banner from "../Banner/Banner";

import Nursing from "../Courses/Nursing";
import { Spinner } from "../spinner/spinner"; // Import the Spinner component

const NursingRoute = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    // Cleanup timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div>
        {isLoading ? (
          <Spinner color="#0482f0" size={32} />
        ) : (
          <>
            <Topheader />
            <Header />
            <Banner info="Nursing" />
            {/* Conditional rendering of spinner while loading */}
            <div style={{ marginTop: "100px", marginBottom: "100px" }}>
              <Nursing />
            </div>
            <Footer />
          </>
        )}
      </div>
    </>
  );
};

export default NursingRoute;
