// destinationData.js

import skorea from "./korea.jpg"; // South Korea image
// DestinationDetails.jsx
import React from "react";
import "./destination.css";
export const destinations = [
  {
    name: "South Korea",
    description:
    `Studying in Korea from Nepal opens up a world of opportunities for
       students seeking a unique and dynamic educational experience. With its cutting-edge technology, vibrant culture, and top-ranking universities, Korea offers a truly immersive learning environment like no other. From bustling cities to serene countryside, 
       students can explore diverse landscapes while pursuing their academic goals.
       South Korea's education system is renowned globally, with institutions that emphasize research, innovation, and practical knowledge. Universities such as Seoul National University, Korea University, and Yonsei University are not only prestigious but also provide a wide array of programs in various fields of study, ensuring that students can find courses that align with their
        academic and career aspirations.
       ` ,
    requirements: [
      "All Nepali Students must be over 18 years of age.",
      "Students who have passed +2.",
      "Preparing to study in Korean Universities.",
      "Be able to prove that their financial status is genuine.",
    ],
    otherDocuments: [
      "A valid passport with at least six months validity remaining.",
      "A completed visa application form.",
      "Proof of acceptance from a recognized educational institution in South Korea.",
      "Financial statements showing that you can support yourself during your stay.",
      "A health certificate confirming your good health status.",
      "Proof of accommodation arrangements in Korea.",
      "Statement of Purpose (SOP).",
      "Embassy verified transcript and Provisional Certificate of High School.",
      "Grade-Sheet and Certificate of SEE, also verified by the Embassy.",
      "Student and parents’ citizenship documents, translated into English and notarized.",
      "Color copies of your IELTS Certificate and Passport, both notarized.",
      "Transfer Character Certificate of High School and SEE, notarized.",
      "Ward Verification documents, including birth certificate, address, annual income, family relationship, and tax clearance verification.",
    ],
    image: skorea,
  },
];

const KoreaDestinationDetails = () => {
  return (
    <>
      {destinations.map((destination, index) => (
        <div className="">
          <div className="container mb-20">
            <div className="destination-details ">
              <div className="row">
                <div className="col-md-12">
                  <div className="section-wrapper">
                    <div className="section-title text-justify text-black">
                      <h3 style={{ fontWeight: "bold", color: "#0482f0" }}>
                        Destination
                      </h3>
                      <p
                        style={{
                          color: "#000",
                          textAlign: "justify",
                          fontWeight: "400",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        Subedi & Associated provides you different destination
                        for your higher studies.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <div className="destination-details-image ">
                    <img src={destination.image} alt="" />
                  </div>
                </div>

                <div className="col-lg-6 text-justify">
                  <h1
                    style={{
                      fontWeight: "bold",
                      color: "black",
                      paddingBottom: "20px",
                      paddingTop: "20px",
                    }}
                  >
                    {destination.name}
                  </h1>
                  <p
                    style={{
                      color: "black",
                      textAlign: "justify",
                      fontSize: "13px",
                      fontWeight: "400",
                    }}
                  >
                    {destination.description}
                  </p>
                </div>
              </div>

              <div
                className="text-justify pt-5 pr-5 pl-5 text-black"
                style={{
                  color: "black",
                  paddingTop: "50px",
                  fontWeight: "400",
                }}
              >
                  <h1
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    paddingTop: "50px",
                    paddingBottom: "20px",
                    fontSize: "25px",
                  }}
                >
                  What are the requirements ?
                </h1>
                <ul>
                  {destination.requirements.map((requirement, reqIndex) => (
                    <li key={reqIndex}>
                      <p
                    
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      &rarr; &nbsp; {requirement}</p>
                    </li>
                  ))}
                </ul>
              </div>

              <div
                className="text-justify pt-5 pr-5 pl-5 text-black"
                style={{
                  color: "black",
                  paddingTop: "50px",
                  fontWeight: "400",
                }}
              >
                <h1
                  style={{
                    fontWeight: "500",
                    color: "black",
                    paddingBottom: "20px",
                    fontSize: "25px",
                  }}
                >
                  Other Required Documents
                </h1>
                <ul>
                  {destination.otherDocuments.map((document, docIndex) => (
                    <li key={docIndex}>
                      <p  
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >&rarr; &nbsp; {document}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default KoreaDestinationDetails;
