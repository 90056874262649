import React, { Component } from "react";
import $ from "jquery";
import canadas from "./canada.jpg";

class canada extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    $(document).ready(function () {
      $(window).scrollTop(0);
    });
  }

  render() {
    return (
      <>
        <div className="">
          <div className="container">
            <div className="destination-details">
              <div className="row">
                <div className="col-md-12">
                  <div className="section-wrapper">
                    <div className="section-title text-justify text-black">
                      <h3 style={{ fontWeight: "bold", color: "#0482f0" }}>
                        Destination
                      </h3>
                      <p
                        style={{
                          color: "#000",
                          textAlign: "justify",
                          fontWeight: "400",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        Subedi & Associated provides you different destination
                        for your higher studies.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <div className="destination-details-image ">
                    <img src={canadas} alt="" />
                  </div>
                </div>
                <div className="col-lg-6 text-justify">
                  <h1
                    style={{
                      fontWeight: "bold",
                      color: "black",
                      paddingBottom: "20px",
                      paddingTop: "20px",
                    }}
                  >
                    Canada
                  </h1>
                  <p
                    style={{
                      color: "black",
                      textAlign: "justify",
                      fontSize: "13px",
                      fontWeight: "400",
                    }}
                  >
                    Canadian education has garnered a reputation as one of the
                    most dependable pathways for immigration to Canada. The
                    country's inclusive policies, high-quality educational
                    institutions, and welcoming atmosphere make it an attractive
                    destination for students seeking both academic excellence
                    and opportunities for permanent residency. To begin the
                    journey towards studying and immigrating to Canada,
                    individuals must first select a post-secondary designated
                    learning institution (DLI). These DLIs are recognized and
                    approved by the Canadian government to offer programs to
                    international students. Once enrolled in a Diploma,
                    Bachelor's, or Master's degree program at a DLI, students
                    can embark on their educational journey.
                  </p>
                </div>
              </div>
              <div className="text-justify" style={{ marginTop: "20px" }}>
                <p
                  style={{
                    color: "black",
                    textAlign: "justify",
                    fontSize: "13px",
                    fontWeight: "400",
                    paddingTop: "20px",
                  }}
                >
                  <strong>Study Permit (Immigration Services).</strong> Any
                  student, who is going to study in Canada more than 6 months
                  must to get a study permit from CIC. Candidate needs apply
                  online and get approval for study permit and visa in order to
                  enter Canada. As soon as potential students come to Canada,
                  Canadian officer grants study permit documents to student at
                  the Canadian border, in most cases in Canadian airport.
                </p>
              </div>

              <div className="text-justify" style={{ marginTop: "20px" }}>
                <p
                  style={{
                    color: "black",
                    textAlign: "justify",
                    fontSize: "13px",
                    fontWeight: "400",
                  }}
                >
                  If you study at least 1 year, you may get 1 or 2 year open
                  work permit, however when you graduate with 2-4 years degree
                  program you will get work permit for 3 years. During this
                  time, you have to find reliable employer and get eligible NOC
                  position in the company. After 1 year of working for your
                  employer, you will be eligible for getting permanent resident
                  status in Canada.
                </p>
              </div>

              <div
                className="text-left pt-5 pr-5 pl-5 text-black"
                style={{
                  color: "black",
                  paddingTop: "50px",
                  fontWeight: "400",
                }}
              >
                <h1
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    paddingBottom: "20px",
                    fontSize: "25px",
                  }}
                >
                  What are the requirements ?
                </h1>
                <ul>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; Successful enrolment with a registered
                      course
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; Meet the Genuine Temporary Entrant
                      requirement
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; Sufficient funds for airfares, course fees
                      and living costs
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; English language proficiency
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; Meet health and character requirements
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; Appropriate Health Insurance- Overseas
                      Student Health Cover (OSHC)
                    </p>
                  </li>
                </ul>
              </div>

              <div
                className="text-left pt-5 pr-5 pl-5 text-black"
                style={{
                  color: "black",
                  paddingTop: "50px",
                  fontWeight: "400",
                }}
              >
                <h1
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    paddingBottom: "20px",
                    fontSize: "25px",
                  }}
                >
                  Other Required Documents
                </h1>
                <ul>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; A completed application form.
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; Certified copies of all the pages including
                      the biographical pages of the current passports or travel
                      documents of all people included in the application.
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; If your name has changed or the name of
                      anyone included in your application has changed: a
                      certified copy of evidence of the name change.
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; Certified copies of birth certificates for
                      you and any other person included in the application. The
                      certificates must show the names of both parents of each
                      person. 2 MRP passport Sized Photos
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; Certified copies of marriage certificates or
                      relationship registrations for you and anyone else
                      included in your application, even if they are not joining
                      you in Australia.
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; If you are living in a de facto
                      relationship: independent evidence that you have been in
                      the relationship for at least 12 months (for example,
                      joint bank account statements or billing accounts in joint
                      names).
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; If you or anyone included in the application
                      has been widowed, divorced or permanently separated: a
                      certified copy of the death certificate, divorce decree
                      absolute, or statutory declaration/separation certificate.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default canada;
