import React, { Component } from "react";
import $ from "jquery";
import ab from "./abdeg.jpg";
import "./Studyabroad.css";
import dbm from "./dbm.jpg";

class Studyabroad extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    $(document).ready(function () {
      $(window).scrollTop(0);
    });
  }

  render() {
    return (
      <>
        <div className="">
          <div className="container">
            <div className="">
              <div className="row">
                <div className="col-md-7">
                  <div className="section-wrapper">
                    <div className="section-title text-justify text-black">
                      <h3 style={{ fontWeight: "bold", color: "#0482f0" }}>
                        WHAT WE OFFER ?
                      </h3>
                      <p
                        style={{
                          color: "#000",
                          textAlign: "justify",
                          fontWeight: "400",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        Subedi & Associated provides different services.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-between">
                <div className="col-lg-6">
                  <img
                    src={dbm}
                    alt=""
                    style={{
                      width: "100%",
                      height: "400px",
                      padding: "10px",
                      borderRadius: "10px",
                      boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
                      objectFit: "cover",
                      objectPosition: "center",
                      marginBottom: "20px",
                      marginTop: "10px",
                      aspectRatio: "1/1",
                    }}
                  />
                </div>
                <div className="col-lg-6 align-self-end">
                  <div
                    className="text-justify pt-5 pr-5 pl-5 text-black "
                    style={{ color: "black", fontWeight: "400" }}
                  >
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      <strong>Subedi & Associated </strong>
                      vision is to emerge as the foremost Overseas Education
                      Consulting Organization, setting the benchmark for
                      excellence in the industry. Our aspiration is to craft
                      compelling success narratives for our students, while also
                      delivering significant value to our esteemed partner
                      institutions and all other stakeholders involved. We are
                      dedicated to providing comprehensive services for students
                      seeking education opportunities abroad across various
                      countries. Our mission is to empower individuals with the
                      knowledge, resources, and support necessary to fulfill
                      their academic and professional aspirations on a global
                      scale."
                    </p>

                    <ul>
                      <div className="row">
                        <div className="col-lg-6">
                          <li>
                            <p
                              style={{
                                color: "#0482f0",
                                textAlign: "justify",
                                fontSize: "13px",
                                fontWeight: "bold",
                              }}
                            >
                              {" "}
                              &rarr; &nbsp; UK
                            </p>
                          </li>
                        </div>

                        <div className="col-lg-6">
                          <li>
                            <p
                              style={{
                                color: "#0482f0",
                                textAlign: "justify",
                                fontSize: "13px",
                                fontWeight: "bold",
                              }}
                            >
                              {" "}
                              &rarr; &nbsp; USA
                            </p>
                          </li>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6">
                          <li>
                            <p
                              style={{
                                color: "#0482f0",
                                textAlign: "justify",
                                fontSize: "13px",
                                fontWeight: "bold",
                              }}
                            >
                              {" "}
                              &rarr; &nbsp; CANADA
                            </p>
                          </li>
                        </div>

                        <div className="col-lg-6">
                          <li>
                            <p
                              style={{
                                color: "#0482f0",
                                textAlign: "justify",
                                fontSize: "13px",
                                fontWeight: "bold",
                              }}
                            >
                              {" "}
                              &rarr; &nbsp; AUSTRALIA
                            </p>
                          </li>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <li>
                            <p
                              style={{
                                color: "#0482f0",
                                textAlign: "justify",
                                fontSize: "13px",
                                fontWeight: "bold",
                              }}
                            >
                              {" "}
                              &rarr; &nbsp; NEW ZEALAND
                            </p>
                          </li>
                        </div>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className=""
          style={{
            background: "#FFF",
            paddingBottom: "50px",
            marginTop: "50px",
          }}
        >
          <div className="container">
            <div className="objective">
              <div className="row">
                <div className="col-md-7">
                  <div className="section-wrapper">
                    <div className="section-title text-justify text-black">
                      <h3 style={{ fontWeight: "bold", color: "#0482f0" }}>
                        Our Services
                      </h3>
                      <p
                        style={{
                          color: "#000",
                          textAlign: "justify",
                          fontWeight: "400",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        Subedi & Associated provides the best services.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="blog-main-text ">
                      <div
                        class="c-details-img  "
                        style={{ paddingTop: "5px", width: "100%" }}
                      >
                        <img
                          src={ab}
                          alt=""
                          style={{
                            width: "100%",
                            height: "500px",
                            objectFit: "fill",
                            objectPosition: "center",

                            boxShadow: "0 0 5px #ccc",
                            transition: "all 0.3s ease-in-out",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="" style={{ paddingTop: "20px" }}>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="text-justify" style={{ color: "black" }}>
                        <h4
                          style={{
                            fontWeight: "bold",
                            fontSize: "18px",
                            color: "black",
                            lineHeight: "32px",
                          }}
                        >
                          Counselling & Students Screening:
                        </h4>

                        <p
                          style={{
                            color: "black",
                            textAlign: "justify",
                            fontSize: "13px",
                            fontWeight: "400",
                          }}
                        >
                          Subedi and Associated is an experienced agency in
                          checking the background of Students eligibility for
                          enrolment. Universities, colleges and education
                          providers turn to us because of our safe, secure and
                          customized screening procedure which is unique in the
                          Nepalese prospective student market. We conduct
                          background checks for academic, financial, study gap,
                          career progression, immigration history and most
                          importantly intention to study requirements, working
                          closely with the students. Screenings can mirror the
                          academic and financial potential of Nepalese students
                          where our counsellors are assigned to conduct the
                          process. We offer an online link where students can
                          follow our website and fill-in their information for
                          assessment.
                        </p>

                        <h4
                          style={{
                            fontWeight: "bold",
                            fontSize: "18px",
                            color: "black",
                            lineHeight: "32px",
                          }}
                        >
                          Universities and College Selection & Application:
                        </h4>

                        <p
                          style={{
                            color: "black",
                            textAlign: "justify",
                            fontSize: "13px",
                            fontWeight: "400",
                          }}
                        >
                          Subedi and Associated organizes our own education
                          fares represented by us. These fairs are only open to
                          institutes represented by Subedi and Associated.
                          Institutes meet Pre-screened and pre assessed students
                          in each location so as to target more serious and
                          focused students. These fairs provide institutes
                          excellent opportunity to meet students face to face
                          and give offer letters to qualified
                        </p>

                        <h4
                          style={{
                            fontWeight: "bold",
                            fontSize: "18px",
                            color: "black",
                            lineHeight: "32px",
                          }}
                        >
                          Guidance on Documentation:
                        </h4>

                        <p
                          style={{
                            color: "black",
                            textAlign: "justify",
                            fontSize: "13px",
                            fontWeight: "400",
                          }}
                        >
                          Subedi and Associated Document Department is designed
                          to help students prepare their own documents and
                          comply with court procedural requirements. We provide
                          a good grasp of the legal and practical rules and
                          procedures, to prepare the documents for admission,
                          confirmation of enrolment and Visa Application. We
                          suggest which forms are appropriate for the
                          circumstances. We also assist, as per the
                          requirements, with the paperwork related to university
                          and Visa authorities. Subedi and Associated takes the
                          worry and anxiety out of “doing it themselves” in
                          situations where there is a visa lodgment by the
                          applicant. Our team acts as a helping hand and assists
                          with paralegal suggestions to prepare the paperwork
                          for the Application.
                        </p>

                        <h4
                          style={{
                            fontWeight: "bold",
                            fontSize: "18px",
                            color: "black",
                            lineHeight: "32px",
                          }}
                        >
                          Scholarship & Interview Assistance:
                        </h4>

                        <p
                          style={{
                            color: "black",
                            textAlign: "justify",
                            fontSize: "13px",
                            fontWeight: "400",
                          }}
                        >
                          The Scholarship Assistance Program works to inform
                          students about the process of applying for
                          private/outside scholarships. From the beginning phase
                          of researching scholarships, to putting the
                          application in the mail, we help students in every
                          step. Interviews are not part of every university’s
                          admission process, many institutions will ask students
                          to meet online before accepting their application.
                          Subedi and Associated Team assists students to prepare
                          for the interview to show their potential to study at
                          the university. As interviews are scary at the best of
                          times, our guidance gives them a better chance at
                          successfully getting a place at an institution.
                        </p>

                        <h4
                          style={{
                            fontWeight: "bold",
                            fontSize: "18px",
                            color: "black",
                            lineHeight: "32px",
                          }}
                        >
                          Career Counselling:
                        </h4>

                        <p
                          style={{
                            color: "black",
                            textAlign: "justify",
                            fontSize: "13px",
                            fontWeight: "400",
                          }}
                        >
                          Career counselling is one of the important features
                          belonged to Subedi and Associated. Feel free to visit
                          us and discover the difference. Our experts are always
                          there to help you. Come as you are, we make you stay
                          smart on the walks of life.
                        </p>

                        <h4
                          style={{
                            fontWeight: "bold",
                            fontSize: "18px",
                            color: "black",
                            lineHeight: "32px",
                          }}
                        >
                          Offer Letter Confirmation:
                        </h4>

                        <p
                          style={{
                            color: "black",
                            textAlign: "justify",
                            fontSize: "13px",
                            fontWeight: "400",
                          }}
                        >
                          Subedi and Associated will go through all the
                          application procedures till the applicant is accepted
                          in the program and receive I-20/Offer Letter from
                          respective university/college. Expertise guide line in
                          all delicate steps will forerun the vital decision of
                          a life time-studying abroad.
                        </p>

                        <h4
                          style={{
                            fontWeight: "bold",
                            fontSize: "18px",
                            color: "black",
                            lineHeight: "32px",
                          }}
                        >
                          Visa Lodgment:
                        </h4>

                        <p
                          style={{
                            color: "black",
                            textAlign: "justify",
                            fontSize: "13px",
                            fontWeight: "400",
                          }}
                        >
                          The visa system is complex and at times confusing and
                          constantly changing, that may result to the
                          inaccuracies. Incomplete or wrong visa applications
                          and mistakes may lead to delays or unsuccessful
                          results. So, these mistakes are painful and expensive
                          since visa application fee are non-refundable and the
                          Department of Immigration does not take responsibility
                          for the applications that you make. Thus, Subedi and
                          Associated is very serious about application and
                          ensures a perfect and error free visa lodgment
                        </p>

                        <h4
                          style={{
                            fontWeight: "bold",
                            fontSize: "18px",
                            color: "black",
                            lineHeight: "32px",
                          }}
                        >
                          Pre Departure Briefing:
                        </h4>

                        <p
                          style={{
                            color: "black",
                            textAlign: "justify",
                            fontSize: "13px",
                            fontWeight: "400",
                          }}
                        >
                          Subedi and Associated conducts a Pre-departure
                          Briefing for students after Visa Approval from
                          respective Embassy. The briefing covers topics ranging
                          from academic life, accommodation, cost of living and
                          much more. The sole purpose of this is to help
                          international students take their First Step. It is a
                          pre-departure guide for students, which combines
                          general advice on immigration, fees and maintenance
                          requirements with guidance on preparing for a
                          student’s journey, arriving that country and so forth.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-justify" style={{ paddingLeft: "24px" }}>
                  <h1
                    style={{
                      fontWeight: "bold",
                      color: "black",
                      fontSize: "25px",
                      marginTop: "30px",
                    }}
                  >
                    Some of Other Services :
                  </h1>
                </div>
                <div
                  class="text-justify"
                  style={{
                    color: "black",
                    fontWeight: "600",
                    paddingTop: "20px",
                    paddingLeft: "24px",
                  }}
                >
                  <ul>
                    <li>
                      <p>
                        {" "}
                        &rarr; &nbsp; Classes of IELTS and TOEFL Test with
                        practical guidance and booking their exams/tests
                      </p>
                    </li>
                    <li>
                      <p> &rarr; &nbsp; Itinerary support (if asked)</p>
                    </li>

                    <li>
                      <p>
                        {" "}
                        &rarr; &nbsp; Organize accommodation and picking-up
                        service to airport (if asked)
                      </p>
                    </li>
                    <li>
                      <p>
                        {" "}
                        &rarr; &nbsp; Sensitize on job prospects and upgraded
                        education
                      </p>
                    </li>
                    <li>
                      <p>
                        {" "}
                        &rarr; &nbsp; Suggest a right legal advisor as per their
                        case
                      </p>
                    </li>
                    <li>
                      <p>
                        {" "}
                        &rarr; &nbsp; Organize homestay, guardianship services
                        for the clients
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Studyabroad;
