import "./Services.css";
import React from "react";
import { BiPaperPlane } from "react-icons/bi";
import { GiPencil } from "react-icons/gi";
import { FaSnowflake } from "react-icons/fa";

const Services = () => {
  return (
    <>
      <section
        className="amazing_feature"
        style={{ background: "#FFF", paddingBottom: "150px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="section-wrapper">
                <div className="section-title text-justify text-black">
                  <h3 style={{ fontWeight: "bold", color: "#0482f0" }}>
                    WHAT WE OFFER ?
                  </h3>
                  <p
                    style={{
                      color: "#000",
                      textAlign: "justify",
                      fontWeight: "400",
                      fontFamily: "sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    Subedi & Associated provides different services.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-6 col-xs-12 text-justify">
              <a href="/Study_Abroad">
                <div className="single_feature">
                  <div className="feature_icon">
                    <i className="flex justify-center items-center">
                      <BiPaperPlane />
                    </i>
                  </div>
                  <h3>Study Abroad</h3>
                  <p>The best experience you will find for studying abroad. </p>
                </div>
              </a>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12 text-justify">
              <a href="/IELTS_Service">
                <div className="single_feature">
                  <div className="feature_icon">
                     <i className="flex justify-center items-center">
                      <GiPencil />
                    </i>
                  </div>
                  <h3>IELTS</h3>
                  <p>We have the best professionals for preparations</p>
                </div>
              </a>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12 text-justify">
              <a href="/Visit_Visa">
                <div className="single_feature">
                  <div className="feature_icon">
                     <i className="flex justify-center items-center">
                      <FaSnowflake />
                    </i>
                  </div>
                  <h3>Visitors Visa</h3>
                  <p>
                    We also provide you the guidance and best service for visit
                    visa{" "}
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Services;
