import { NormalIcon, PersonIcon, ScholarshipIcon, VisaIcon } from "../svg/svg";
import "./About.css";
import React from "react";

const About = () => {
  return (
    <>
      <section
        className="about-fun-area"
        style={{ backgroundColor: "#f8f9fa" }}
      >
        <div className="container">
          <div className="about-containers">
            <div className="about-content text-left">
              <h1
                style={{
                  maxWidth: "500px",
                  lineHeight: "50px",
                  fontWeight: "bold",
                  color: "white",
                  fontSize: "36px",
                  textTransform: "uppercase",
                  textShadow: "2px 2px 4px rgba(4, 130, 240, 0.6)",
                  padding: "10px 0 10px 0",
                }}
              >
                Why Choose Subedi & Associates?
              </h1>
            </div>
            <div className="b-details-text event-text p-5">
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <div className="c-details-feature flex flex-col justify-center items-center">
                      <NormalIcon />
                      <h4>Comprehensive Study Plans</h4>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="c-details-feature flex flex-col justify-center items-center">
                      <PersonIcon />
                      <h4>Compact Guidance by Experts</h4>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="c-details-feature flex flex-col justify-center items-center">
                      <ScholarshipIcon />
                      <h4>Scholarship Assistance</h4>
                    </div>
                  </div>
                </div>
                <div className="row mt-10">
                  <div className="col-md-4">
                    <div className="c-details-feature flex flex-col justify-center items-center">
                      <VisaIcon />
                      <h4>Free Visa Processing</h4>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="c-details-feature flex flex-col justify-center items-center">
                      <NormalIcon />
                      <h4>Student Support Service</h4>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="c-details-feature flex flex-col justify-center items-center">
                      <PersonIcon />
                      <h4>Career Counselling</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
