import React from "react";
import { FiFacebook } from "react-icons/fi";
import { AiFillInstagram } from "react-icons/ai";
import { SiGmail } from "react-icons/si";
import "./Topheader.css";

const Topheader = () => {
  return (
    <div className="bg-[#0482f0]  p-1 md:py-2 text-white header-top">
      <div className="flex justify-center md:justify-between items-center max-w-screen-xl mx-auto px-4">
        <div className="w-full flex justify-center md:justify-between items-center px-4">
          <div className="font-medium">
            <span className="mr-4">
              <i className="fa fa-envelope" aria-hidden="true"></i> &nbsp;
              subedi.edu2018@gmail.com
            </span>
            <span>
              <i className="fa fa-phone" aria-hidden="true"></i> &nbsp;
              +977-023532220
            </span>
          </div>
          <div className="flex space-x-3">
            <a href="/" className="text-xl">
              <FiFacebook />
            </a>
            <a href="/" className="text-xl">
              <AiFillInstagram />
            </a>
            <a href="mailto:mail.subedi.edu2018@gmail.com" className="text-xl">
              <SiGmail />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topheader;
