import React, { Component } from "react";
import "./Banner.css";

class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <section className="banner_area " style={{ marginTop: "" }}>
          <div className="container">
            <div className="banner_inner_text">
              <h4>{this.props.info}</h4>
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li className="active">
                  <a href="/Services">&nbsp; {this.props.info}</a>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </>
    );
  }
}
export default Banner;
