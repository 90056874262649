import "./About.css";
import React from "react";
import deg from "./deg.jpg";
import ab from "./ab.jpg";

const AboutPage = () => {
  return (
    <>
      <div
        className="blog-details-area pb-150 "
        style={{ marginTop: "50px", paddingTop: "0px", paddingBottom: "0px" }}
      >
        <div className="container">
          <div className="aboutpage-content">
            <div className="card-body">
              <div className="row">
                <div className="col-md-7">
                  <div
                    className="section-wrapper"
                    style={{ marginTop: "20px" }}
                  >
                    <div className="section-title text-justify text-black">
                      <h3 style={{ fontWeight: "bold", color: "#0482f0 " }}>
                        About US
                      </h3>
                      <p
                        style={{
                          color: "#000",
                          textAlign: "justify",
                          fontWeight: "400",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        Subedi & Associated is best educational consultancy.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="about-page-details-img ">
                    <img src={deg} alt="d" />
                  </div>
                </div>

                <div className="about-page-text text-justify">
                  <div className="col-md-6">
                    <p
                      style={{
                        color: "#000",
                        textAlign: "justify",
                        fontWeight: "400",
                        fontFamily: "sans-serif",
                        fontSize: "14px",
                      }}
                    >
                      <strong>Subedi and Associated </strong> was established in
                      Birtamode, Jhapa, Nepal in 2018 with the goal of providing
                      genuine advice for student visa, travel service to the
                      students and clients. Subedi and Associated always aims at
                      building long lasting relationships and values their
                      morals, ethics, stepping stone in their carrier for study
                      abroad. The company is also registered in Inland Revenue
                      Department, Ministry of Finance in Nepal under VAT System
                      on 27th June, 2018. Subedi and Associated has its
                      registered office in Birtamode Municipality, Ward No 04,
                      Mukti Chowk, Birtamode, Jhapa, Nepal.
                    </p>
                    <p
                      style={{
                        color: "#000",
                        textAlign: "justify",
                        fontWeight: "400",
                        fontFamily: "sans-serif",
                        fontSize: "14px",
                      }}
                    >
                      Since the establishment in 2018 of this company it has not
                      only been helping the international students to find the
                      colleges and universities but also providing them the
                      career counselling guidance on the right courses and level
                      according to their interest, affordability and eligibility
                      as well as the current immigration rules. Student’s
                      recruitment is the core responsibility of our company.
                    </p>
                  </div>
                  <p
                    style={{
                      color: "#000",
                      textAlign: "justify",
                      fontWeight: "400",
                      fontFamily: "sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    Our counselors are very knowledgeable in student placement
                    and are geared with industry experience to provide students
                    the best service Subedi and Associated has to offer. They
                    allocate ample time to understand student’s & parents
                    specific requirements. Students’ academic credentials and
                    financial status are carefully analyzed in order to find the
                    most appropriate and affordable course of study in a given
                    university or any other institution.
                  </p>

                  <p
                    style={{
                      color: "#000",
                      textAlign: "justify",
                      fontWeight: "400",
                      fontFamily: "sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    Subedi and Associated understands and respects the decision
                    made by the parents & the student to embark on a higher
                    course of study in a foreign country and we strive
                    constantly to make the decision a correct one with the
                    financial strain. Our goal is to ensure that the students
                    directed and counseled by Subedi and Associated, secure a
                    placement in the best possible university or institution
                    within their range of affordability and that they pass out
                    with a globally recognized qualification. A foreign
                    education is an expensive investment, (“Nepali students
                    returning to the country after studies will bring new skill
                    and expertise which will be helpful in building a new
                    Nepal.”) but one that will pay rich dividends when students
                    make that crucial choice to step up to excellence.
                  </p>
                  <div className="row">
                    <div className="col-md-6">
                      <div
                        className="about-page-details-img "
                        style={{
                          marginTop: "20px",
                          marginBottom: "20px",
                        }}
                      >
                        <img src={ab} alt="d" />
                      </div>
                    </div>
                    <div className="col-md-6 ">
                      <p
                        style={{
                          color: "#000",
                          textAlign: "justify",
                          fontWeight: "400",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                          marginTop: "20px",
                        }}
                      >
                        Subedi and Associated have created an illusionary world
                        for success it gratified the traits of students dreams
                        which are really intensified the key to foreign success
                        our profession is not merely profession but it’s a key
                        to success. Students beliefs are our beliefs indeed we
                        are providing them full transparency support along with
                        the wide range of scopes and opportunities we always try
                        to put this into head that each and every students may
                        eventually concern with the current scenario and after
                        that they kick off into the mainstream. Our main focuses
                        on the aspects of each and every students their keen
                        interest on their field of study, countries like
                        Australia, UK, New-Zealand, Canada, USA, France, South
                        Korea, India, UAE and many more countries along with
                        hopes comes true and trigger out their target easily. If
                        our students can get complex with unresolved puzzles we
                        can make them create other options. Subedi and
                        Associated conduct seminars, conferences and open up the
                        plethora of avenues for the students.
                      </p>
                    </div>

                    <p
                      style={{
                        color: "#000",
                        textAlign: "justify",
                        fontWeight: "400",
                        fontFamily: "sans-serif",
                        fontSize: "14px",
                      }}
                    >
                      Subedi and Associated also assists to our staff
                      contribution thriving on good and initiative teamwork to
                      prompt services, better planning, providing equal guidance
                      till the customer/student satisfaction ,discipline
                      management system, structured good ideas, innovation,
                      quick in decision making, keep controlling in good
                      management activity, never diverting the students and
                      sharing with them valuable information. In such remarkable
                      evidence, tie up with the international schools,
                      boundaries worldwide events for student’s conference
                      workshops. We are pioneers with various countries like
                      Australia, UK, New-Zealand, Canada, USA, France, South
                      Korea, India, UAE and many European Countries. We only
                      provide the details according to the conveniences of the
                      student/customer and specially their requirement for
                      specific countries.
                    </p>

                    <p
                      style={{
                        color: "#000",
                        textAlign: "justify",
                        fontWeight: "400",
                        fontFamily: "sans-serif",
                        fontSize: "14px",
                      }}
                    >
                      Subedi and Associated provides extra effort and evaluation
                      in the procedures, if any queries prevails/occurs we
                      maintain feedback agenda, telephonic conversation to
                      integrate word of mouth advertisements with special
                      preference given to qualitative work, face to face meeting
                      while taking into consideration to this our activist try
                      to improve their hopes/desires /demand/ converges. If any
                      changes or rules are considered during admission time or a
                      new program comes to the market we subsequently are aware
                      about that and inform the students.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="" style={{ paddingTop: "35px" }}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="tab-text " style={{ color: "black" }}>
                    <h1
                      style={{
                        fontWeight: "bold",
                        fontSize: "26px",
                        color: "black",
                        lineHeight: "32px",
                        textAlign: "left",
                        paddingTop: "10px",
                      }}
                    >
                      Subedi and Associated Marketing Strategy to promote our
                      partner institutions:
                    </h1>

                    <p
                      style={{
                        color: "#000",
                        textAlign: "justify",
                        fontWeight: "400",
                        fontFamily: "sans-serif",
                        fontSize: "14px",
                      }}
                    >
                      Taking premium positions in the education / business
                      publications conveying rational reasons to explore the
                      Option of International Education. Participating in and
                      organizing exhibitions, Seminars / Career fairs in
                      Association with foreign institutions.In addition to our
                      proactive engagement with publications, we actively engage
                      in the organization and participation of exhibitions,
                      seminars, and career fairs in collaboration with esteemed
                      foreign institutions. These events serve as invaluable
                      platforms for individuals to gain firsthand insights into
                      the world of international education, connect with
                      representatives from renowned universities and colleges
                      abroad, and explore a diverse range of academic and career
                      pathways.{" "}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="blog-main-text">
                    <div className="c-details-img mt-2 ">
                      <img
                        src="./assets/img/blog/l-2.jpg"
                        alt=""
                        style={{
                          height: "400px",
                          width: "100%",
                          aspectRatio: "16/9",
                          boxShadow:
                            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="" style={{ paddingTop: "35px" }}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="text-justify" style={{ color: "black" }}>
                      <h4
                        style={{
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: "black",
                          lineHeight: "32px",
                        }}
                      >
                        Face to face counselling:
                      </h4>

                      <p
                        style={{
                          color: "#000",
                          textAlign: "justify",
                          fontWeight: "400",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        Our offices are located in prime location in city and
                        daily large number of students and their parents visit
                        our office for their query. We are a group of
                        international graduates having good experience in
                        education recruitment team. With the help of experts
                        students are taking counselling about colleges,
                        education system, course details and fee structure etc.
                      </p>

                      <h4
                        style={{
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: "black",
                          lineHeight: "32px",
                        }}
                      >
                        Fairs:
                      </h4>

                      <p
                        style={{
                          color: "#000",
                          textAlign: "justify",
                          fontWeight: "400",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        Subedi and Associated organize our own education fair’s
                        represent by us. These fairs are only open to institutes
                        represented by Subedi and Associated. Institutes meet
                        Pre-screened and pre assessed students in each location
                        so as to target more serious and focused students. These
                        fairs provide institutes excellent opportunity to meet
                        students face to face and give offer letters to
                        qualified
                      </p>

                      <h4
                        style={{
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: "black",
                          lineHeight: "32px",
                        }}
                      >
                        Direct Focused Marketing:
                      </h4>

                      <p
                        style={{
                          color: "#000",
                          textAlign: "justify",
                          fontWeight: "400",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        Subedi and Associated design more focused marketing
                        activities targeting the specific audience/students. It
                        is far more important to have a personal and more
                        focused approach to each individual market and offer the
                        right product to the right individual. Identifying
                        customers according to the Product and focusing on
                        maintaining the Market support.
                      </p>

                      <h4
                        style={{
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: "black",
                          lineHeight: "32px",
                        }}
                      >
                        Public Relation:
                      </h4>

                      <p
                        style={{
                          color: "#000",
                          textAlign: "justify",
                          fontWeight: "400",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        to be featured in credible forums to announce the new
                        features and achievements. Take leadership position in
                        the Career space by contributing articles / information
                        in the Leading publications. Organize and participate in
                        events to enhance the brand building of the company.
                      </p>

                      <h4
                        style={{
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: "black",
                          lineHeight: "32px",
                        }}
                      >
                        Advertising:
                      </h4>

                      <p
                        style={{
                          color: "#000",
                          textAlign: "justify",
                          fontWeight: "400",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        Taking premium positions in the education / business
                        publications conveying rational reasons to explore the
                        Option of International Education. Facilitating the
                        support systems available through well-established
                        offices. Regular Editorial support in the Popular
                        Publications in Nepal.
                      </p>

                      <h4
                        style={{
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: "black",
                          lineHeight: "32px",
                        }}
                      >
                        Events:
                      </h4>

                      <p
                        style={{
                          color: "#000",
                          textAlign: "justify",
                          fontWeight: "400",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        Participating in and organizing exhibitions, Seminars /
                        Career fairs in Association with foreign institutions to
                        give more personal interaction to the students and
                        Opening their Minds to various learning opportunities
                        they have Abroad. Hence interact with the customers more
                        personally and demonstrate and build credibility of the
                        company & associated Institutes.
                      </p>

                      <h4
                        style={{
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: "black",
                          lineHeight: "32px",
                        }}
                      >
                        Presentations:
                      </h4>

                      <p
                        style={{
                          color: "#000",
                          textAlign: "justify",
                          fontWeight: "400",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        Promotions in schools & colleges – Build mind share with
                        the future workforce. Presenting the new opportunities
                        offered to them and building a whole New World for them.
                        Working Ties with the schools & Colleges to have
                        Education Events conducted regularly in their Premises.
                        Inviting our Institutes to give presentations in the
                        well know schools, Colleges & Universities to create
                        more Direct awareness on the Opportunities available to
                        students.
                      </p>

                      <h4
                        style={{
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: "black",
                          lineHeight: "32px",
                        }}
                      >
                        Workshops:
                      </h4>

                      <p
                        style={{
                          color: "#000",
                          textAlign: "justify",
                          fontWeight: "400",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        to conduct regular workshops for intellect sharing’
                        concept. The workshops are conducted in association with
                        Nepalese Colleges and Universities & Universities we
                        represent to have face 2 face open room discussions. The
                        workshops will also be focused on particular courses
                        done in order to allow more interactivity between the
                        faculty from abroad and students enrolled for their
                        courses.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AboutPage;
