import React, { Component } from "react";
import $ from "jquery";
import ukh from "./uk.jpg";

class uk extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    $(document).ready(function () {
      $(window).scrollTop(0);
    });
  }

  render() {
    return (
      <>
        <div className="">
          <div className="container">
            <div className="destination-details">
              <div className="row">
                <div className="col-md-7">
                  <div className="section-wrapper">
                    <div className="section-title text-justify text-black">
                      <h3 style={{ fontWeight: "bold", color: "#0482f0" }}>
                        Destination
                      </h3>
                      <p
                        style={{
                          color: "#000",
                          textAlign: "justify",
                          fontWeight: "400",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        Subedi & Associated provides you different destination
                        for your higher studies.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <img src={ukh} alt="" />
                </div>
                <div className="col-lg-6 text-justify">
                  <h1
                    style={{
                      fontWeight: "bold",
                      color: "black",
                      paddingBottom: "20px",
                      paddingTop: "10px",
                    }}
                  >
                    United Kingdom
                  </h1>
                  <p
                    style={{
                      color: "black",
                      textAlign: "justify",
                      fontSize: "13px",
                      fontWeight: "400",
                    }}
                  >
                    The United Kingdom stands as one of the largest economies in
                    the world, boasting a rich history of innovation, academic
                    excellence, and cultural diversity. Renowned for its
                    prestigious universities, cutting-edge research facilities,
                    and dynamic job market, the UK offers a wealth of
                    opportunities for students seeking to further their
                    education and career prospects. One of the most attractive
                    aspects of studying in the UK is the abundance of job
                    opportunities available to graduates.
                    <br />
                    With its robust economy and diverse industries spanning
                    finance, technology, healthcare, and more, the UK provides a
                    fertile ground for career advancement and professional
                    growth. According to the Office for National Statistics
                    survey of 2018, the average salary in the UK stands at
                    £29,009, offering competitive remuneration for skilled
                    professionals.
                  </p>
                </div>
              </div>

              <div
                className="text-justify pt-5 pr-5 pl-5 text-black"
                style={{
                  color: "black",
                  paddingTop: "50px",
                  fontWeight: "400",
                }}
              >
                <h1
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    paddingTop: "50px",
                    paddingBottom: "20px",
                    fontSize: "25px",
                  }}
                >
                  Study in the UK{" "}
                </h1>
                <ul>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; The UK is home to many of the world’s
                      leading universities and offers international students an
                      academic experience that is hard to beat.
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; International students will be offered a
                      two-year work visa after graduating from a British
                      university
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; Over 80% of international alumni would
                      recommend studying in the UK
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; English language proficiency
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      &rarr; &nbsp; Over 69% feel that being an alumnus of a UK
                      university has allowed them to progress more quickly in
                      their career
                    </p>
                  </li>
                </ul>
              </div>

              <div
                className="text-justify pt-5 pr-5 pl-5 text-black"
                style={{
                  color: "black",
                  paddingTop: "20px",
                  paddingBottom: "30px",
                  fontWeight: "400",
                }}
              >
                <p
                  style={{
                    color: "black",
                    textAlign: "justify",
                    fontSize: "13px",
                    fontWeight: "400",
                  }}
                >
                  All British universities and colleges accept IELTS Academic
                  score. This means you may not need to take an IELTS for UKVI
                  (Academic) test because you may be able to submit an IELTS
                  Academic result. Before booking your test, make sure to check
                  which test and score you need with the university or college
                  you are applying to.
                </p>

                <p
                  style={{
                    color: "black",
                    textAlign: "justify",
                    fontSize: "13px",
                    fontWeight: "400",
                  }}
                >
                  <h3
                    style={{
                      fontWeight: "bold",
                      color: "black",
                      paddingTop: "50px",

                      fontSize: "25px",
                    }}
                  >
                    Post-study work visa
                  </h3>
                  <br />
                  The UK government has announced a new two-year post-study work
                  visa. The visa will be open to all international students who
                  have valid UK immigration status as a student and have
                  successfully completed a course of study in any subject at
                  undergraduate level or above at an approved UK Higher
                  Education Provider. The visa will allow eligible students to
                  work, or look for work, in any career or position of their
                  choice, for two years after completing their studies.
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default uk;
