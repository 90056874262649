import React from "react";
import manageImage from "./manage.jpg";
import "./Courses.css";

const Management = () => {
  const data = {
    title: "Management",
    description:
      " Subedi & Associated welcomes you to embark on an extraordinary educational voyage, unlocking access to premier Management universities worldwide. Discover tailored programs spanning Diplomas, Masters, and Ph.D. degrees, offered across renowned institutions in Australia, USA, Canada, New Zealand, and an array of other countries. Immerse yourself in an enriching academic odyssey characterized by immersive, hands-on experiences, meticulously designed to hone your skills and expertise. Conclude your journey with a transformative Capstone Experience, refining your practical competencies and preparing you for the dynamic demands of the professional landscape. Armed with a distinguished degree, you'll emerge primed for success, equipped with a competitive advantage that grants access to prestigious leadership programs, cutting-edge facilities, and coveted industry placements. Take the decisive step forward and join us in shaping a future brimming with promise and possibility.",
  };

  return (
    <div className="container mx-auto">
      <div className="row">
        <div className="col-md-7">
          <div className="">
            <div className="section-title text-justify text-black">
              <h3
                style={{ fontWeight: "bold", color: "#0482f0" }}
                className="section-heading"
              >
                {data.title}
              </h3>
              <p
                style={{
                  color: "#000",
                  textAlign: "justify",
                  fontWeight: "400",
                  fontFamily: "sans-serif",
                  fontSize: "14px",
                }}
                className="section-description"
              >
                Subedi & Associated offers you the best platform for studying
                Management.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row" style={{ paddingTop: "50px", paddingBottom: "50px" }}>
        <div className="col-lg-6">
          <div className="engineering-image-wrapper">
            <img
              src={manageImage}
              alt="Engineering"
              className="engineering-image"
            />
          </div>
        </div>
        <div className="col-lg-6 text-justify">
          <p
            style={{
              color: "black",
              textAlign: "justify",
              fontSize: "13px",
              fontWeight: "400",
            }}
            className="engineering-description"
          >
            {data.description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Management;
