import React, { useState, useEffect } from 'react';
import $ from "jquery";
import Topheader from '../Header/Topheader';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Banner from '../Banner/Banner';
import Destination from '../Destination/Destination';
import { Spinner } from '../spinner/spinner';

const DestinationRoute = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timerHandle = setTimeout(() => setIsLoading(false), 1000);
        $(document).ready(function(){
            $(this).scrollTop(0);
        });

        // Cleanup function to clear timeout in case component unmounts before loading is done
        return () => clearTimeout(timerHandle);
    }, []);

    return (
        <>
            {isLoading ? (
                <Spinner/>
              
            ) : (
                <div>
                    <Topheader />
                    <Header />
                    <Banner info="Destination" />
                    <div style={{ marginTop: "100px" , marginBottom:"100px"}}>
                    <Destination />
                  </div>
                    <Footer />
                </div>
            )}
        </>
    );
};

export default DestinationRoute;
