import React, { useState, useEffect } from "react";
import $ from "jquery";
import Topheader from "../Header/Topheader";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Banner from "../Banner/Banner";
import Finland from "../Destination/finland";
import { Spinner } from "../spinner/spinner";

const FinlandRoute = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);
    $(document).ready(function () {
      $(this).scrollTop(0);
    });

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading ? (
        // Handle loading state if needed
        <Spinner />
      ) : (
        <div>
          <Topheader />
          <Header />
          <Banner info="Finland" />
          <Finland />
          <Footer />
        </div>
      )}
    </>
  );
};

export default FinlandRoute;
