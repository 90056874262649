import React, { useState, useEffect } from "react";
import Topheader from "../Header/Topheader";
import Header from "../Header/Header";
import Slider from "../Slider/Slider";
import Services from "../Services/Services";
import Destination from "../Destination/Destination";
import About from "../About/About";
import Aboutus from "../About/Aboutus";
import Footer from "../Footer/Footer";
import Scroll from "../Scroll/Scroll";
import { Spinner } from "../spinner/spinner";

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <Topheader />
          <Header />
          <Slider />
          <Services />
          <Destination />
          <About />
          <Aboutus />
          <Footer />
          <Scroll />
        </div>
      )}
    </>
  );
};

export default Home;
