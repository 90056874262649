import React from "react";
import e from "./e.jpg";
import "./Courses.css";

// Engineering data
const data = {
  title: "Engineering",
  description: `Subedi & Associated offers you the best Engineering universities for your Diploma, Masters, and Ph.D. in Engineering in Australia, USA, Canada, New Zealand, and other countries too. This graduate certificate will equip you with the necessary skills to address the demands of the modern power industry. You will be able to identify, critically analyze, and creatively solve intellectually complex, specialized professional engineering problems in power generation, underground and overhead power system design, and safety earthing in electrical systems. Upon completion of this program, you will gain skills and knowledge in the latest developing technologies in electrical systems. The curriculum is designed to provide hands-on experience with cutting-edge tools and technologies, ensuring that graduates are well-prepared for the challenges of the engineering industry. Throughout the program, you will work on real-world projects, collaborate with industry professionals, and gain valuable insights into the field of engineering.`,
};

const Engineering = () => {
  return (
    
      <div className="container mx-auto">
    
          <div className="row" >
            <div className="col-md-12">
              <div className="" >
                <div className="section-title text-justify text-black">
                  <h3
                    style={{ fontWeight: "bold", color: "#0482f0" }}
                    className="section-heading"
                  >
                    {data.title}
                  </h3>
                  <p
                    style={{
                      color: "#000",
                      textAlign: "justify",
                      fontWeight: "400",
                      fontFamily: "sans-serif",
                      fontSize: "14px",
                    }}
                    className="section-description"
                  >
                    Subedi & Associated offers you the best platform for
                    studying Engineering.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ paddingTop: "50px", paddingBottom: "50px" }}>
            <div className="col-lg-6">
            <div className="engineering-image-wrapper" >
              <img src={e} alt="Engineering" className="engineering-image" />
            </div>
            </div>
            <div className="col-lg-6 text-justify">
              <p
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontSize: "13px",
                  fontWeight: "400",
                }}
                className="engineering-description"
              >
                {data.description}
              </p>
            </div>
          </div>
        </div>
  
  );
};

export default Engineering;
