import React from "react";
import "./Board.css";
import roshan from "./roshan.jpg";

const Board = () => {
  return (
    <div className="" style={{ paddingTop: "50px" }}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-wrapper">
              <div className="section-title text-justify text-black">
                <h3 style={{ fontWeight: "bold", color: "#0482f0" }}>
                  Message from Subedi & Associated 's CEO
                </h3>
                <p
                  style={{
                    color: "#000",
                    textAlign: "justify",
                    fontWeight: "400",
                    fontFamily: "sans-serif",
                    fontSize: "14px",
                  }}
                >
                  Let's know what our CEO say's
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="message-content">
        <div className="row ">
          <div className="col-sm-3 ">
            <div className="img-profile ">
              <span>
                <img
                  src={roshan}
                  alt=""
                  style={{
                    height: "200px",
                    width: "200px",
                    borderRadius: "50%",
                    alignItems: "center",

                  marginTop: "20px",
                  padding:"10px",

                    boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
                    objectFit: "cover",
                    aspectRatio: "1/1",
                  }}
                />
              </span>
            </div>
          </div>

          <div className="col-sm-9 ">
            <div className="team-member">
              
                <div className="text-justify">
                  <p
                   style={{
                      color: "#000",
                      textAlign: "justify",
                      fontWeight: "400",
                      fontFamily: "sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    <strong style={{ color: "#0482f0" }}>
                      Subedi & Associated Pvt.Ltd{" "}
                    </strong>{" "}
                    Ltd. is a reputable institution founded with a clear
                    mission: to emerge as the foremost Overseas Education
                    Consulting Organization. Our aim is to cultivate success
                    stories for our students while simultaneously adding value
                    to our partner institutions and all stakeholders involved.
                    At Subedi & Associated, we are dedicated to upholding the
                    highest standards of excellence, honesty, integrity, and
                    continuously updating our knowledge base. Our commitment
                    extends to fostering strong and trustworthy relationships
                    with our partner institutions, serving as a reliable bridge
                    across cultures, and prioritizing support for the academic
                    pursuits of the younger generation. Thank you for
                    considering Subedi & Associated as a place to begin or
                    further your career.
                  </p>
                </div>
                <div className="" style={{ paddingTop: "20px" }}>
                  <h2>Er. Roshan Subedi</h2>
                </div>
                <hr className="header-hr" />
                <h4
                  className="title"
                  data-animate-in="right"
                  data-animate-in-delay="10 "
                >
                  Chief Executive Officer
                </h4>
                <ul
                  className="social-icon "
                  data-animate-in="left"
                  data-animate-in-delay="10 "
                >
                  <li>
                    <a
                      href="https://www.facebook.com/profile.php?id=100001046600461"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <i className="fa fa-facebook "></i>
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fa fa-twitter "></i>
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Board;
