import React, { Component } from "react";
import un from "./uni.jpg";

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <section
          className="blog-area pb-100 blog-section"
          style={{ marginTop: "100px", paddingBottom: "50px" }}
        >
          <div className="container">
            <div className="section-wrapper">
              <div className="section-title text-justify text-black">
                <h3 style={{ fontWeight: "bold", color: "#0482f0 " }}>
                  Our Blog?
                </h3>
                <p
                  style={{
                    color: "#000",
                    textAlign: "justify",
                    fontWeight: "400",
                    fontFamily: "sans-serif",
                    fontSize: "14px",
                  }}
                >
                  {" "}
                  Subedi and Associated is one of the most trusted educational
                  consultancy.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div
                  className="ht-single-blog"
                  style={{ boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)" }}
                >
                  <div className="ht-blog-img">
                    <a href="/Blog_Details">
                      <img
                        src={un}
                        alt=""
                        style={{ height: "350px", width: "100%" }}
                      />
                    </a>
                  </div>
                  <div className="ht-blog-text">
                    <span className="ht-post-meta" style={{ color: "black" }}>
                      25 March 2020
                    </span>
                    <h4>
                      <a href="/Blog_Details" style={{ fontWeight: "bold" }}>
                        The best universities in Australia to study hospitality
                        and tourism in 2021
                      </a>
                    </h4>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      Australia’s stunning natural scenery and sunny weather
                      make it a top tourist destination, and our fresh local
                      produce and numerous wine regions inspire chefs worldwide.
                      With international travel on hold for the time being,
                      there has been a boom in domestic tourism.{" "}
                    </p>
                    <a href="/Blog_Details" style={{ color: "#0482f0" }}>
                      Read More <i className="fa fa-long-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="ht-single-blog"
                  style={{ boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)" }}
                >
                  <div className="ht-blog-img">
                    <a href="/#">
                      <img
                        src="./assets/img/blog/l-3.jpg"
                        alt=""
                        style={{ height: "350px", width: "100%" }}
                      />
                    </a>
                  </div>
                  <div className="ht-blog-text">
                    <span className="ht-post-meta" style={{ color: "black" }}>
                      30 July 2019
                    </span>
                    <h4>
                      <a href="/Blog" style={{ fontWeight: "bold" }}>
                        Education System in Canada
                      </a>
                    </h4>
                    <p
                      style={{
                        color: "black",
                        textAlign: "justify",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      The Canadian education system offers three degrees:
                      bachelor's, master's, and doctorate degrees. As an
                      international student, you are welcome to complete all or
                      part of your education in Canada. Like other countries,
                      there are both public and private universities
                    </p>
                    <a href="/#" style={{ color: "#0482f0" }}>
                      Read More <i className="fa fa-long-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
export default Blog;
