import React, { useState, useEffect } from "react";
import Topheader from "../Header/Topheader";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Banner from "../Banner/Banner";
import Hotel from "../Courses/Hotel";
import { Spinner } from "../spinner/spinner";

const HotelRoute = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulating loading delay
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    // Clear the timer on unmount to prevent memory leaks
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <Topheader />
            <Header />
            <Banner info="Hotel" />
            <div style={{ marginTop: "100px" , marginBottom:"100px"}}>
            <Hotel />
            </div>
            <Footer />
          
          </>
        )}
      </div>
    </>
  );
};

export default HotelRoute;
