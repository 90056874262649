import "./About.css";
import React from "react";
import ab from "./ab.jpg";

const Aboutus = () => {
  return (
    <>
      <section className="history-area" style={{ marginTop: "50px" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-7 text-center">
              <div className="section-wrapper">
                <div className="section-title text-justify text-black">
                  <h3 style={{ fontWeight: "bold", color: "#0482f0 " }}>
                    Who are We ?
                  </h3>
                  <p
                    style={{
                      color: "#000",
                      textAlign: "justify",
                      fontWeight: "400",
                      fontFamily: "sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    {" "}
                    Subedi and Associated is one of the reputed educational
                    consultancy.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div
                id="history-slid"
                className="carousel slide"
                data-ride="carousel"
                data-interval="false"
              >
                <div className="py-5">
                  <div className=" row">
                    <div className="col-lg-6 col-md-12 pl-0">
                      <div className="history-img">
                        <img
                          className="img-fluid h-full md:h-[450px] mt-5"
                          src={ab}
                          alt=""
                         
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 pr-0">
                      <div className=" text-left">
                        <h2 style={{ marginTop: "20px" }}>
                          {" "}
                          <span
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                              color: "#0482f0",
                            }}
                          >
                            {" "}
                            Subedi & Associated Pvt Ltd
                          </span>
                        </h2>
                        <p
                          style={{
                            color: "black",
                            textAlign: "justify",
                            fontSize: "13px",
                            fontWeight: "400",
                            paddingTop: "15px",
                          }}
                        >
                          Subedi and Associated was established in Birtamode,
                          Jhapa, Nepal in 2018 with the noble goal of providing
                          genuine advice and assistance to students seeking
                          visas for studying abroad. With a strong commitment to
                          integrity and authenticity, we strive to guide
                          students through the intricate process of obtaining
                          student visas, ensuring transparency and reliability
                          every step of the way. In addition to our visa
                          consultation services, we also offer comprehensive
                          travel assistance to students and clients. Whether
                          it's arranging travel logistics, accommodation, or
                          other related services, our dedicated team is here to
                          facilitate a smooth and hassle-free experience for our
                          clients.
                          <br />
                          At Subedi and Associated, we are passionate about
                          making dreams a reality and empowering students to
                          reach their full potential. Join us on this exciting
                          adventure as we embark on a journey of discovery,
                          growth, and success together.
                        </p>
                        <div className="" style={{ marginTop: "20px" }}>
                          <a
                            className="who-button"
                            href="About_US"
                            title="Learn More"
                          >
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Aboutus;
