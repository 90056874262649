import React from "react";
import "./destination.css";

// Importing images
import ab from "./aus.jpg";
import canada from "./canada.jpg";
import n from "./new.jpg";
import uk from "./uk.jpg";
import usa from "./usa.jpg";
import finland from "./finland.jpeg";
import korea from "./korea.jpg";
import japan from "./japan.jpg";
import dubai from "./dubai.jpeg";

// Array of destinations
export const destinations = [
  {
    name: "Australia",
    image: ab,
    link: "/Australia",
    description:
      "Australia is a premier destination for international students. Its world-class education system attracts learners from around the globe.",
  },
  {
    name: "USA",
    image: usa,
    link: "/USA",
    description:
      "Citizens of foreign countries who wish to enter the United States must first obtain a visa, either a non-immigrant visa for temporary residents.",
  },
  {
    name: "Canada",
    image: canada,
    link: "/Canada",
    description:
      "It's no secret: Canadian education stands out as the most dependable and accessible pathway to immigration in Canada.",
  },
  {
    name: "New Zealand",
    image: n,
    link: "/Newzealand",
    description:
      "New Zealand, a beautiful and unique country in the South Pacific, where exciting things are happening in Education.",
  },
  {
    name: "UK",
    image: uk,
    link: "/UK",
    description:
      "The UK is home to many of the world’s leading universities and offers international students an academic experience that is hard to beat.",
  },
  {
    name: "Finland",
    image: finland,
    link: "/Finland",
    description:
      "Finland is the perfect destination to boost your career prospects, with plenty of opportunities for employment after graduation.",
  },
  {
    name: "South Korea",
    image: korea,
    link: "/SouthKorea",
    description:
      "South Korea offers a unique blend of traditional culture and modern innovation, with top-ranking universities and a vibrant lifestyle.",
  },
  {
    name: "Japan",
    image: japan,
    link: "/Japan",
    description:
      "Studying in Japan offers a unique blend of traditional culture and technological advancements, providing a rich academic experience and global networking opportunities.",
  },
  {
    name: "Dubai",
    image: dubai,
    link: "/Dubai",
    description:
      "Dubai is a diverse city-state known for its modern architecture, vibrant culture, and robust educational opportunities. It's a preferred destination for students seeking a unique academic and cultural experience.",
  },
];

const Destination = () => {
  return (
    <section className="course-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-wrapper">
              <div className="section-title text-justify text-black">
                <h3 style={{ fontWeight: "bold", color: "#0482f0" }}>
                  Explore Destinations
                </h3>
                <p
                  style={{
                    color: "#000",
                    textAlign: "justify",
                    fontWeight: "400",
                    fontFamily: "sans-serif",
                    fontSize: "14px",
                  }}
                >
                  Discover your ideal study destination.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {destinations.map((destination, index) => (
            <div key={index} className="col-md-3 col-sm-6 mb-4">
              <div className="ht-single-course hover pb-3">
                <div className="ht-course-image">
                  <a href={destination.link}>
                    <img
                      src={destination.image}
                      alt={destination.name}
                      className="img-fluid h-[200px] w-full"
                    />
                  </a>
                </div>
                <div className="ht-course-text text-center p-3">
                  <h4 className="mb-3">
                    <a href={destination.link} className="text-dark">
                      Study in {destination.name}
                    </a>
                  </h4>
                  <p className="mb-3 text-[12px] leading-relaxed text-black text-justify px-2">
                    {destination.description}
                  </p>
                  <div className="readmore-btn">
                    <a href={destination.link} className="btn btn-primary">
                      Read More <i className="fa fa-long-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Destination;
