import React, { Component } from "react";
import $ from "jquery";
import Topheader from "../Header/Topheader";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import UK from "../Destination/uk";
import Banner from "../Banner/Banner";
import { Spinner } from "../spinner/spinner";
// Corrected import statement for Spinner

class UkRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    // Simulate loading delay
    setTimeout(() => {
      this.setState({ isLoading: false });
      $(document).scrollTop(0); // Scroll to top after loading
    }, 1500);
  }

  render() {
    return (
      <>
        {this.state.isLoading ? (
          <Spinner /> // Corrected syntax for conditional rendering
        ) : (
          <div>
            <Topheader />
            <Header />
            <Banner info="UK" />
            <UK />
            <Footer />
          </div>
        )}
      </>
    );
  }
}

export default UkRoute;
