import "./App.css";
// import AboutRoute from "./Components/Router/AboutRoute";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./Components/Router/Home";
import AustraliaRoute from "./Components/Router/AustraliaRoute";
import USARoute from "./Components/Router/USARoute";
import CanadaRoute from "./Components/Router/CanadaRoute";
import UkRoute from "./Components/Router/UKRoute";
import NewzRoute from "./Components/Router/newzRoute";
import FinlandRoute from "./Components/Router/Finland";

import EngineeringRoute from "./Components/Router/EngineeringRoute";
import ManagementRoute from "./Components/Router/ManagementRoute";
import NursingRoute from "./Components/Router/NursingRoute";
import HealthRoute from "./Components/Router/HealthRoute";
import ITRoute from "./Components/Router/ITRoute";
import CoursesRoute from "./Components/Router/CoursesRoute";
import LeaderRoute from "./Components/Router/LeaderRoute";
import HotelRoute from "./Components/Router/HotelRoute";
import ServicesRoute from "./Components/Router/ServicesRoute";
import DestinationRoute from "./Components/Router/DestinationRoute";
import ContactRoute from "./Components/Router/ContactRoute";
import AboutRoute from "./Components/Router/AboutRoute";
import BlogRoute from "./Components/Router/BlogRoute";
import BlogDetailsRoute from "./Components/Router/BlogDetailsRoute";
import MessageRoute from "./Components/Router/MessageRoute";
import FAQRoute from "./Components/Router/FAQRoute";
import ObjectiveRoute from "./Components/Router/ObjeciveRoute";
import AbroadRoute from "./Components/Router/AbroadRoute";
import IELTSRoute from "./Components/Router/IELTSroute";
import VisitRoute from "./Components/Router/VisitRoute";
import SouthKoreaRoute from "./Components/Router/KoreaRoute";
import JapanRoute from "./Components/Router/JapanRoute";
import DubaiRoute from "./Components/Router/DubaiRoute";


function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/USA" component={USARoute} />
          <Route exact path="/Australia" component={AustraliaRoute} />
          <Route exact path="/Canada" component={CanadaRoute} />
          <Route exact path="/Uk" component={UkRoute} />
          <Route exact path="/Newzealand" component={NewzRoute} />
          <Route exact path="/Finland" component={FinlandRoute} />
          <Route exact path="/Engineering" component={EngineeringRoute} />
          <Route exact path="/Management" component={ManagementRoute} />
          <Route exact path="/Nursing" component={NursingRoute} />
          <Route exact path="/HealthandScience" component={HealthRoute} />
          <Route exact path="/Informations" component={ITRoute} />
          <Route exact path="/Courses" component={CoursesRoute} />
          <Route exact path="/Services" component={ServicesRoute} />
          <Route
            exact
            path="/LeadershipandManagement"
            component={LeaderRoute}
          />
          <Route exact path="/HotelManagement" component={HotelRoute} />
          <Route exact path="/Destination" component={DestinationRoute} />
          <Route exact path="/Contact_US" component={ContactRoute} />
          <Route exact path="/About_US" component={AboutRoute} />
          <Route exact path="/Blog" component={BlogRoute} />
          <Route exact path="/Blog_Details" component={BlogDetailsRoute} />
          <Route exact path="/Board" component={MessageRoute} />
          <Route exact path="/FAQ" component={FAQRoute} />
          <Route exact path="/SouthKorea" component={SouthKoreaRoute} />
          <Route exact path="/Japan" component={JapanRoute} />
          <Route exact path="/Dubai" component={DubaiRoute} />

          <Route exact path="/Objective" component={ObjectiveRoute} />
          <Route exact path="/Study_Abroad" component={AbroadRoute} />
          <Route exact path="/IELTS_Service" component={IELTSRoute} />
          <Route exact path="/Visit_Visa" component={VisitRoute} />

          {/* <Route exact path="/About_US" component={AboutRoute} />
          <Route exact path="/Objective" component={ObjectiveRoute} />
          <Route exact path="/Study_Abroad" component={AbroadRoute} />
          <Route exact path="/IELTS_Service" component={IELTSRoute} />
          <Route exact path="/Visit_Visa" component={VisitRoute} />
          <Route exact path="/Australia" component={AustraliaRoute} />
          <Route exact path="/USA" component={USARoute} />
          <Route exact path="/Canada" component={CanadaRoute} />
          <Route exact path="/Uk" component={UkRoute} />
          <Route exact path="/Newzealand" component={NewzRoute} />
          <Route exact path="/Courses" component={CoursesRoute} />
          <Route exact path="/Services" component={ServicesRoute} />
          <Route exact path="/Destination" component={DestinationRoute} />
          <Route exact path="/Contact_US" component={ContactRoute} />
          <Route exact path="/Blog" component={BlogRoute} />
          <Route exact path="/Blog_Details" component={BlogDetailsRoute} />
          <Route exact path="/FAQ" component={FAQRoute} />
          <Route exact path="/Gallery" component={GalleryRoute} />
          <Route exact path="/Board" component={MessageRoute} />
          <Route exact path="/Engineering" component={EngineeringRoute} />
          <Route exact path="/Management" component={ManagementRoute} />
          <Route exact path="/Nursing" component={NursingRoute} />
          <Route exact path="/HealthandScience" component={HealthRoute} />
          <Route exact path="/Informations" component={ITRoute} /> */}
          {/* <Route
            exact
            path="/LeadershipandManagement"
            component={LeaderRoute}
          />
          <Route exact path="/HotelManagement" component={HotelRoute} /> */}
          <Route component={Error} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
