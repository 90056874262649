import React, { Component } from "react";
import ab from "./aus.jpg";
import "./destination.css";

class Finland extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="">
          <div className="container">
            <div className="destination-details ">
              <div className="row">
                <div className="col-md-12">
                  <div className="section-wrapper">
                    <div className="section-title text-justify text-black">
                      <h3 style={{ fontWeight: "bold", color: "#0482f0" }}>
                        Destination
                      </h3>
                      <p
                        style={{
                          color: "#000",
                          textAlign: "justify",
                          fontWeight: "400",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        Subedi & Associated provides you different destination
                        for your higher studies.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <div className="destination-details-image ">
                    <img src={ab} alt="" />
                  </div>
                </div>

                <div className="col-lg-6 text-justify">
                  <h1
                    style={{
                      fontWeight: "bold",
                      color: "black",
                      paddingBottom: "20px",
                      paddingTop: "20px",
                    }}
                  >
                    Finland
                  </h1>
                  <p
                    style={{
                      color: "black",
                      textAlign: "justify",
                      fontSize: "13px",
                      fontWeight: "400",
                    }}
                  >
                    Finland stands out as an exceptional destination for
                    individuals seeking to enhance their career prospects
                    through higher education. With a strong emphasis on
                    innovation, research, and practical learning, Finland offers
                    abundant opportunities for employment and professional
                    growth post-graduation. The country's renowned higher
                    education system is recognized globally for its excellence
                    and inclusivity. One of the most compelling aspects of
                    studying in Finland is the extensive range of English-taught
                    bachelor's and master's degree programs available. With over
                    500 programs offered across 13 universities and 22
                    universities of applied sciences (UAS), students have access
                    to a diverse array of academic disciplines and
                    specializations. Whether aspiring to pursue studies in
                    technology, business, arts, or sciences, Finland's
                    universities provide comprehensive and cutting-edge programs
                    designed to meet the demands of the modern workforce.
                  </p>
                </div>
              </div>
              <p
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontSize: "13px",
                  fontWeight: "400",
                }}
              >
                <br />
                <strong>Finland is </strong> located in the far North of Europe,
                between its neighbours Sweden and Russia. The main language is
                Finnish; Swedish is also an official language thanks to a
                Swedish minority, a result of 600 years as part of the Swedish
                Empire. Helsinki, the capital, is by far the largest city in
                Finland. Other large towns include Espoo, Tampere and Turku. You
                can apply Finland,First you should decide for which programs at
                which universities you want to apply. You will find the entry
                requirements either in the course descriptions here on Study.EU
                or on the university websites. The Finnish polytechnics handle
                all applications for Bachelor programs and many applications for
                Master programs through their central application system at
                Studyinfo.fi. For degree programs not handled via that website,
                you need to apply directly to the polytechnic or university
                according to their admissions process.
              </p>

              <p
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontSize: "13px",
                  fontWeight: "400",
                }}
              >
                <br />
                <strong>Students</strong> from the EU/EEA can study in Finland
                without any further visa requirements. Students from other
                countries may need to obtain a specific study visa. You should
                check with the Finnish authorities to know how to meet the legal
                requirements. Finland is a welcoming country for foreigners.
                However, the big challenge for international graduates is the
                Finnish language. It is considered to be one of the world’s most
                complicated languages. Even though almost everyone in Finland
                has a decent knowledge of English, it is vital to know at least
                a bit of Finnish for the workplace. But the Finnish authorities
                are doing their best to attract and keep talent in Finland.
              </p>
              <p
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontSize: "13px",
                  fontWeight: "400",
                }}
              >
                <h3
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    paddingTop: "50px",

                    fontSize: "25px",
                  }}
                >
                  Post-study work visa
                </h3>
                <br />
                If you are someone who is outside of the EU and complete a
                masters degree in Finland, you are granted a post-study work
                visa for one year. You are allowed to look for a job while
                staying in Finland for one year after graduation has been
                completed. If you are able to obtain full-time employment, then
                you can apply for a work visa or a work based residence permit.
                After that, if you work in Finland full-time for four
                consecutive years, you are allowed to apply for the permanent
                residence permit.
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Finland;
