import React, { useState, useEffect } from "react";
import Topheader from "../Header/Topheader";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Courses from "../Courses/Courses";
import Banner from "../Banner/Banner";
import { Spinner } from "../spinner/spinner";

const CoursesRoute = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading process with setTimeout
    const timeout = setTimeout(() => {
      setIsLoading(false); // Once loading is done, set isLoading to false
    }, 2000); // Simulating 2 seconds of loading time

    // Cleanup function to clear timeout in case component unmounts before loading is done
    return () => clearTimeout(timeout);
  }, []); // Empty dependency array to run the effect only once on mount

  return (
    <>
      {isLoading ? ( // Conditional rendering based on isLoading state
        <Spinner/> // Display loader if isLoading is true
      ) : (
        <div>
          <Topheader />
          <Header />
          <Banner info="Courses" />
          <Courses />
          <Footer />
        </div>
      )}
    </>
  );
};

export default CoursesRoute;
