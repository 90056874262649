import React from "react";

import e from "./back.jpg";
import "./Courses.css";

const data = {
    title: "Information Technology",
    description: `Subedi & Associated is dedicated to guiding you towards the finest Information Technology (IT) universities worldwide, ensuring you access to comprehensive programs for both Diploma and Masters degrees in IT. Whether you aspire to study in Australia, the USA, Canada, New Zealand, or any other country, we facilitate your journey towards the best educational institutions offering cutting-edge IT courses.

    With IT being a global field, possessing proficiency in this domain opens doors to countless opportunities across the globe. Our expert guidance and tailored recommendations empower you to seize these opportunities, regardless of your desired destination.
    
    In particular, our Master's program in Computer Science provides a rigorous and expansive curriculum, covering advanced algorithms, computer security, artificial intelligence, machine learning, and more. Through specialized tracks, students cultivate expertise in niche areas, preparing them for dynamic careers at the forefront of technological innovation.`,
  };


const IT = () => {
  return (
    <div className="container mx-auto">
    
    <div className="row" >
      <div className="col-md-7">
        <div className="" >
          <div className="section-title text-justify text-black">
            <h3
              style={{ fontWeight: "bold", color: "#0482f0" }}
              className="section-heading"
            >
              {data.title}
            </h3>
            <p
              style={{
                color: "#000",
                textAlign: "justify",
                fontWeight: "400",
                fontFamily: "sans-serif",
                fontSize: "14px",
              }}
              className="section-description"
            >
              Subedi & Associated offers you the best platform for
              studying  Information Technology.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="row" style={{ paddingTop: "50px", paddingBottom: "50px" }}>
    
      <div className="col-lg-6 text-justify">
        <p
          style={{
            color: "black",
            textAlign: "justify",
            fontSize: "13px",
            fontWeight: "400",
          }}
          className="engineering-description"
        >
          {data.description}
        </p>
      </div>
      <div className="col-lg-6">
      <div className="engineering-image-wrapper" >
        <img src={e} alt="Engineering" className="engineering-image" />
      </div>
      </div>
    </div>
  </div>
  );
};

export default IT;
