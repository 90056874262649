import React, { Component } from "react";
import ielts from "./ielts.jpeg";

class IELTS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  render() {
    return (
      <>
        <div className="">
          <div className="container">
            <div className="objective">
              <div className="row">
                <div className="col-md-12">
                  <div className="section-wrapper">
                    <div className="section-title text-justify text-black">
                      <h3 style={{ fontWeight: "bold", color: "#0482f0" }}>
                        IELTS
                      </h3>
                      <p
                        style={{
                          color: "#000",
                          textAlign: "justify",
                          fontWeight: "400",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        Subedi & Associated provides the classes of IELTS
                        preparations and test by professionals.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <img
                    src={ielts}
                    alt=""
                    style={{
                      aspectRatio:"1/1",
                      width: "100%",
                      borderRadius: "10px",
                      boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    }}
                  />
                </div>
                <div className="col-lg-6 text-justify">
                  <p
                    style={{
                      color: "#000",
                      textAlign: "justify",
                      fontWeight: "400",
                      fontFamily: "sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    <strong> At
                    Subedi & Associated, </strong> we are committed to providing you with
                    access to top-notch professionals who specialize in IELTS
                    preparation. With our comprehensive support and guidance,
                    you can embark on your IELTS journey with confidence,
                    knowing that you're in capable hands. IELTS, recognized by
                    over 10,000 organizations worldwide, offers a trusted
                    platform for assessing English language proficiency. Whether
                    you're seeking admission to educational institutions,
                    exploring job opportunities, applying for immigration visas,
                    or pursuing professional certifications, the IELTS test is
                    widely accepted and respected by educational institutions,
                    employers, governments, and professional bodies globally.
                    Having pioneered four skills English language testing three
                    decades ago, IELTS continues to set the gold standard for
                    language assessment today. Governments in key
                    English-speaking countries such as Australia, Canada, New
                    Zealand, and the United Kingdom rely on IELTS scores to
                    evaluate language proficiency for immigration purposes,
                    further underscoring its credibility and relevance in
                    today's interconnected world."
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default IELTS;
