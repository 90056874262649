import React, { Component } from "react";
import a from "./business.jpg";
import b from "./family.jpg";
import c from "./beautiful.jpeg";

class VisitVisa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  render() {
    return (
      <>
        <div className="object">
          <div className="container">
            <div className="objective">
              <div className="row">
                <div className="col-md-7">
                  <div className="section-wrapper" style={{padding:"20px"}}>
                    <div className="section-title text-justify text-black">
                      <h3 style={{ fontWeight: "bold", color: "#0482f0" }}>
                        BUSINESS VISITORS
                      </h3>
                      <p
                        style={{
                          color: "#000",
                          textAlign: "justify",
                          fontWeight: "400",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        Subedi & Associated also provides business visa .
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row" style={{ padding: "50px" }}>
                <div className="col-lg-6">
                  <img
                    src={a}
                    alt=""
                    style={{
                      width: "100%",
                      height: "400px",
                      padding: "10px",
                      borderRadius: "10px",
                      boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
                      objectFit: "cover",
                      objectPosition: "center",
                      marginBottom: "20px",
                      marginTop: "10px",
                      aspectRatio: "1/1",
                    }}
                  />
                </div>
                <div className="col-lg-6 text-justify">
                  <p
                    style={{
                      color: "black",
                      textAlign: "justify",
                      fontSize: "13px",
                      fontWeight: "400",
                    }}
                  >
                    <strong> Business Visa </strong> is strictly for business
                    dealings and negotiations etc. You cannot work on a business
                    visa. It would be illegal to seek employment on a business
                    visa. You cannot just apply for a business visa. You will
                    have to provide proof and related documents to show that you
                    are visiting a country on business. On a business visa, you
                    can attend meetings, get trained and deal in a business
                    where you are neither paid nor employed.
                    <br />
                    As one of the pioneers of four skills English language
                    testing 30 years ago, IELTS continues to set the standard
                    for English language testing today. Governments in
                    Australia, Canada, New Zealand and the UK use IELTS to
                    process immigration applications.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="object">
          <div className="container">
            <div className="objective">
              <div className="row">
                <div className="col-md-7">
                  <div className="section-wrapper" style={{padding:"20px"}}>
                    <div className="section-title text-justify text-black">
                      <h3 style={{ fontWeight: "bold", color: "#0482f0" }}>
                        SPONSORED FAMILY VISA
                      </h3>
                      <p
                        style={{
                          color: "#000",
                          textAlign: "justify",
                          fontWeight: "400",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        Subedi & Associated also provides the Sponsored family
                        Visa.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row" style={{ padding: "50px" }}>
                <div className="col-lg-6">
                  <img
                    src={b}
                    alt=""
                    style={{
                      width: "100%",
                      height: "400px",
                      padding: "10px",
                      borderRadius: "10px",
                      boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
                      objectFit: "cover",
                      objectPosition: "center",
                      marginBottom: "20px",
                      marginTop: "10px",
                      aspectRatio: "1/1",
                    }}
                  />
                </div>
                <div className="col-lg-6 text-justify">
                  <p
                    style={{
                      color: "#000",
                      textAlign: "justify",
                      fontWeight: "400",
                      fontFamily: "sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    <strong> Subedi & Associated</strong> will also process for
                    the Sponsored Family visa for different countries like USA,
                    AUSTRALIA, CANADA, NEW ZEALAND, UK.
                    <br />
                    The Sponsored visa granted is usually based on the
                    documentation of applicant's Sponsor and applicant as
                    well.The visa granted change as per the rules of countries
                    and the applicants are not allowed to work during their
                    stay. The sponsor may be asked to pay a security bond.
                    <br />
                    To be eligible for the applicant should be financialy
                    strong, healthy and character requirement, applicant will
                    not be taking part in an education or training in Australia
                    lasting for a period longer than 3 months .
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="object">
          <div className="container">
            <div className="objective">
              <div className="row">
                <div className="col-md-7">
                  <div className="section-wrapper" style={{padding:"20px"}}>
                    <div className="section-title text-justify text-black">
                      <h3 style={{ fontWeight: "bold", color: "#0482f0" }}>
                        TURIST VISA
                      </h3>
                      <p
                        style={{
                          color: "#000",
                          textAlign: "justify",
                          fontWeight: "400",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        Subedi & Associated also provides the tourist visa .
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <img
                    src={c}
                    alt=""
                    style={{
                      width: "100%",
                      height: "400px",
                      padding: "10px",
                      borderRadius: "10px",
                      boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
                      objectFit: "cover",
                      objectPosition: "center",
                      marginBottom: "20px",
                      marginTop: "10px",
                      aspectRatio: "1/1",
                    }}
                  />
                </div>
                <div className="col-lg-6 text-justify">
                  <p
                    style={{
                      color: "#000",
                      textAlign: "justify",
                      fontWeight: "400",
                      fontFamily: "sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    <strong> Subedi & Associated</strong> will also process for
                    the tourist visa for different countries . USA, AUSTRALIA,
                    CANADA, NEW ZEALAND, UK
                    <br />
                    The visa granted change as per the rules of countries and
                    the applicants are not allowed to work during their stay.
                    <br />
                    To be eligible for the applicants should be financialy
                    strong, healthy and character requirement, applicants will
                    not be taking part in an education or training in Australia
                    lasting for a period longer than 3 months .
                    <br />
                    You need to have strong documentation for guarantee visa.
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default VisitVisa;
