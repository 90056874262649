import React from "react";
import "./Slider.css";
import a from "./a.jpg";
import c from "./c.jpg";
import d from "./d.jpg";
import e from "./e.jpg";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Slider = () => {
  const items = [
    { image: a },
    { image: c },
    { image: d },
    { image: e },
  ];

  return (
    <Carousel showArrows={true} showThumbs={false} showIndicators={false}>
      {items.map((item, i) => (
        <div key={i} className="sliderimg mt-0 ">
          <img src={item.image} alt={`Slide ${i + 1}`} className="h-full " />
        </div>
      ))}
    </Carousel>
  );
};

export default Slider;
